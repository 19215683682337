import React, {Fragment, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { obtenerPanelesAccion,borrarPanelAccion } from '../redux/panelDucks'
import Menu from './menu.jsx';


const ListaPaneles = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
   
    const paneles = useSelector(store => store.paneles.array)
    
    const[filtronombre, setFiltroNombre]=useState('')
    

    useEffect (async function () {
        console.log("refrescando pantalla");
        await ActualizarPaneles();
        console.log("termine de refrescar");
       }, []);

       async function ActualizarPaneles () {
        await dispatch(obtenerPanelesAccion(filtronombre))
        console.log("actualizado")
        dispatch(obtenerPanelesAccion(filtronombre))
        
       }

    const handleInputChange = (evento) => {
        var todoOK;
        todoOK=true;
        var nombre = evento.target.value;
        setFiltroNombre(nombre)
    }
    
    const NuevoPanel = () => {
        navigate("/nuevopanel");    
    }

    const eliminarElemento = (id) => {
        dispatch(borrarPanelAccion(id))    
        navigate("/paneles");    
        ActualizarPaneles();     
    }

const obtenerPaneles= (evento) => {
    evento.preventDefault()
    dispatch(obtenerPanelesAccion(filtronombre))
}

   return (
        <div>
            <Menu></Menu>
            <hr/>
            <h3>Lista de Paneles </h3>
            <form className='row' onSubmit={obtenerPaneles}>
                <div className="col-md-4">
                    <input 
                        name="PanNom"
                        type="text"
                        placeholder="Nombre del panel"
                        className="form-control"
                        onChange={handleInputChange}
                        />                
                </div>                            
                <div className="col-md-5">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Buscar</button>
                    <button onClick={NuevoPanel}
                        className='btn btn-primary'
                    >Nuevo Panel</button>           
                </div>
            </form>      

<table width="100%" border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Nombre</th>
      <th scope="col" align="left">Acciones</th>
    </tr>
  </thead>
  <tbody>
{
    paneles.map(item => (
<tr>
    <td>
        {item.panNombre}
    </td>
    <td align="left">
    <button type="button" class="btn btn-default" onClick={() => eliminarElemento(item.panId)}>
    <img src='./img/trash.svg'   />
    </button>
    </td>
</tr>

    ))
    }


</tbody>
</table>

        </div>
    )
}
export default ListaPaneles
