import React from 'react';
import swal from 'sweetalert';

//timer:"3000"
const Alerta = (titulo,tipo,mensaje) => {
    var icono;
    if(tipo=="OK")
        icono="success";
    else
        icono="error";
 
    swal(
        {
            title: titulo,
            text: mensaje,
            icon: icono,
            button: "Aceptar"            
        }
    );    
}
export default Alerta