import axios from 'axios'
import sha256 from 'crypto-js/sha256';
import NuevoUsuario from '../components/nuevousuario.jsx';
import entorno from '../config.js'
import Cookies from 'universal-cookie';

const base_url=entorno("BASE_URL");
const cookies = new Cookies();


// constantes
const dataInicial = {
    array : [],
    unUsuario : [],
    offset: 0
}

// types
const OBTENER_USUARIOS_EXITO = 'OBTENER_USUARIOS_EXITO'
const ENVIAR_USUARIO_EXITO = 'ENVIAR_USUARIO_EXITO'
const ENVIAR_CAMBIOCLAVE_EXITO = 'ENVIAR_CAMBIOCLAVE_EXITO'
const OBTENER_UN_USUARIO_EXITO ='OBTENER_UN_USUARIO_EXITO'
const MODIFICAR_USUARIO_EXITO = 'MODIFICAR_USUARIO_EXITO'

// reducer
export default function usuarioReducer(state = dataInicial, action){
    switch(action.type){
         case OBTENER_USUARIOS_EXITO:
            return {...state, array: action.payload}
            case OBTENER_UN_USUARIO_EXITO:
                return {...state, unUsuario: action.payload}
    
        default:
            return state
    }
}

// acciones
export const obtenerUsuariosAccion = (nombre) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(nombre.trim())
        {
            filtro='/'+nombre;
        }
        
        var direc=base_url + 'genexa/seguridad/usuarios' + filtro;
        console.log(direc);
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_USUARIOS_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const obtenerUnUsuarioAccion = (id) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(id.trim())
        {
            filtro='/'+id;
        }
        var direc=base_url + 'genexa/seguridad/usuarios' + filtro;
        console.log(direc);
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_UN_USUARIO_EXITO,
            payload: res.data            
        })        
        console.log(res.data);
    } catch (error) {
        console.log(error)
    }
}


export const modificarUsuarioAccion = (id,datos) => async (dispatch, getState) => {

    //Preparo el objeto que voy a enviar.-
    //No incluyo el ID del elemento
    // OJO con los tipos de datos, si hay que convertir, hay que hacerlo antes.-
    const usuarioModificado = {
        UsrUsuario:datos.usrUsuario,
        UsrNombreCompleto: datos.usrNombreCompleto,
        UsrCorreoUsuario: datos.usrCorreoUsuario,
        UsrRolUsuario: datos.usrRolUsuario
    };
    console.log(usuarioModificado);
    try {
        const res = await axios.put(base_url + 'genexa/seguridad/usuarios/'+id,usuarioModificado)
        dispatch({
            type: MODIFICAR_USUARIO_EXITO,
            payload: res.data
        })

    } catch (error) {
        console.log("Error de envio de datos");
        console.log(error)
    }
}


export const enviarUsuarioAccion = (datos) => async (dispatch, getState) => {

    var password = sha256(datos.UsrClave1).toString();
    password=password.toUpperCase();
    const nuevoUsuario =
    {
            UsrId:0,
            UsrUsuario:datos.UsrUsuario,
            UsrNombreCompleto: datos.UsrNombreCompleto,
            UsrCorreoUsuario: datos.UsrCorreoUsuario,
            UsRolUsurio: datos.UsRolUsurio,
            UsrEstado: datos.UsrEstado,
            UsrClave: password
    }

    console.log(NuevoUsuario)
    try {
        const res = await axios.post(base_url + 'genexa/seguridad/usuarios',nuevoUsuario)
        dispatch({
            type: ENVIAR_USUARIO_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}


export const borrarUsuarioAccion = (id) => async (dispatch, getState) => {

    try {
        const res = await axios.delete(base_url + 'genexa/seguridad/usuarios/'+id)
        dispatch({
            type: ENVIAR_USUARIO_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const enviarCambioClaveAccion = (datos) => async (dispatch, getState) => {

    
    var claveActual = sha256(datos.ClaveActual).toString().toUpperCase();
    var claveNueva1 = sha256(datos.ClaveNueva1).toString().toUpperCase();
    var claveNueva2 = sha256(datos.ClaveNueva2).toString().toUpperCase();

    var UsrNombre=cookies.get('usrUsuario');
    //console.log(UsrNombre);
    //var UsrNombre="rosina";

    const nuevaClave =
    {
            UsrNom: UsrNombre,
            UsrClaveActual: claveActual,
            UsrClaveNueva1: claveNueva1,
            UsrClaveNueva2: claveNueva2
    }

    console.log(nuevaClave)
    
    try {
        const res = await axios.post(base_url + 'genexa/seguridad/usuarios/cambioclave',nuevaClave)
        dispatch({
            type: ENVIAR_CAMBIOCLAVE_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}


