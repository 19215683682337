import React, {Fragment, useState} from 'react'
import { enviarChipAccion,obtenerUnChipAccion } from '../redux/chipDucks'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import validator from 'validator'
import { useParams } from 'react-router-dom';
import Menu from '../components/menu.jsx';

export const Chip = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { id } = useParams();
    
    const[datos, setDatos]=useState(
    {
        //ChipId:0,
        ChipNom:'',
        ChipFabricante:'',
        ChipActivo:true,
        ChipUsrCreacion: "Admin"
       
    });

    //Variable que acumulará los errores encontrados de validación
const[error,setError]=React.useState(null);
const[chipId,setChipId]=React.useState(0);

const[chipNom,setChipNom]=React.useState('');
const[chipFabricante,setChipFabricante]=React.useState('');

const [obtenerChip,setObtenerChip]= useState([]);

const chips = useSelector(store => store.chips.array)

function traerDatosChip () {
    if(chipId>0&chipId<999)
    {
        console.log("trayendo datos del id correspondiente " + chipId)    
        dispatch(obtenerUnChipAccion(chipId));
        //console.log(clientes)
        var ChipNom=''
        chips.map(item => (
            console.log(item.chipNom)
        ))

        console.log(chipNom)

        console.log("el chip nom es " + chipNom);

        setChipId(999) //Prueba para usar una bandera
    }
}

const handleInputChange = (evento) => {
    //Asigno los valores del formulario a las variables locales de la página
    //evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
    setDatos({
        ...datos,
        [evento.target.name] : evento.target.value
    })
    console.log(datos)
};

//Algo que se dispara cuando se pierde el foco
const handleBlur = (e) =>{
    //Disparo el evento de cambio de formulario pues ahi estan los controles
    handleInputChange(e); 
    //setErrores(validarFormulario(datos));
}

    const enviarDatos= (evento) => {
        //preventDefault es para que el formulario no actue de forma automática, sino que yo pueda 
        //controlarlo mediante JS
        evento.preventDefault();
        var problemas='';
        var todoOK;
        todoOK=true;
        if(!datos.ChipNom.trim())
        {
            setError('Nombre chip es obligatorio');
            todoOK=false;
        }

        if(!datos.ChipFabricante.trim())
        {
            setError('Nombre fabricante es obligatorio');
            todoOK=false;
        }
        
        
        if(todoOK)
        {
            console.log("entro envio")
            console.log(datos)
            dispatch(enviarChipAccion(datos));
    
            //asumo que todo salió ok... me voy al listado de nuevo
            navigate("/chips");  
        }
    }

    const SeleccionActivo = [
        {
          label: "Si",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
    ]


    return (
        <div className='container mt-5'>
            <Fragment>
            <Menu></Menu>
            <hr/>
            <h3>Nuevo chip</h3>
            <form name='Frmcliente' className='row' onSubmit={enviarDatos}>

                    {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }

                <div className="col-md-5">
                    <label>
                        Nombre Chip:<input 
                        name="ChipNom"
                        type="text"
                        placeholder="Ingrese nombre chip"
                        className="form-control"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        //value={chipNom}
                        />
                    </label>
                </div>
                <p></p>
                <div className="col-md-5">
                    Fabricante:<input
                    name="ChipFabricante"
                    type="text" 
                    placeholder="Ingrese fabricante"
                    className="form-control"
                    onChange={handleInputChange}          
                    onBlur={handleBlur}     
                    //value={chipFabricante} 
                    />
                </div>
                <p></p>
                <div className="col-md-5">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Guardar</button>
                </div>
                <p></p>

            </form>
            </Fragment>
        </div>
    )



}
export default Chip