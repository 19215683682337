import React, {Fragment, useState,useEffect} from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import validator from 'validator'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { enviarEstudioFiliacionAccion,obtenerIdEstudioFiliacionAccion,obtenerEstudioFiliacionPorIdAccion,editarEstudioFiliacionAccion } from '../redux/estudioFiliacionDucks'
import Cargando from './loading'
import Menu from '../components/menu.jsx';


const EditarEstudioFiliacion =() => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    
    const[error,setError]=React.useState(null);
    const [loading, setLoading] = useState(false);
    const [refrescarObjeto, setrefrescarObjeto] = useState(false);
    const [listaFstudios,setEstudios]= useState([]);
    const { id } = useParams();
    const [resultado,setResultado]= useState();
    const estudios = useSelector(store => store.estudiosFiliaciones.unaFiliacion)
    
    const[datos, setDatos]=useState(estudios[0]);

    React.useEffect (() => {
        
        const obtenerDatos = async () => {            
            try
            {
                setLoading(true);
                const res = await dispatch(obtenerEstudioFiliacionPorIdAccion(id));               
                console.log(res);
                setLoading(false);
            }
            catch(error){
                console.log(error)
            }
        }
        obtenerDatos()      

    }, []);

    useEffect(() => {
        const elEstudio = estudios.filter(estudio => estudios.estId = id);
        if (elEstudio.length > 0)
            return setDatos(elEstudio[0]);
    }, [estudios])


    const cambioResultadoCalculo = (e) =>{
        var valor=Number(e.target.value);
        setDatos({
            ...datos,
            estResCalc : valor
        })
        console.log("Cambio de resultado");
        console.log(e.target.value);
        console.log(datos);        
    };

    const cambioEstado = (e) =>{
        setDatos({
            ...datos,
            estEstado : e.target.value
        })
        console.log("Cambio de estado");
        console.log(e.target.value);
        //console.log(datos);
    };
    

    const handleInputChange = (evento) => {
        setDatos({
            ...datos,
            [evento.target.name] : evento.target.value
        })

    };


    const enviarDatos= (evento) => {
        evento.preventDefault();
        var problemas='';
        var todoOK;
        todoOK=true;
    
    
        if(todoOK)
        {
            console.log(datos)
            dispatch(editarEstudioFiliacionAccion(id, datos));
            navigate("/listado"); 
        }
    }


        return (
            
            <Fragment>
                
            <Menu></Menu>
            <hr/>
                 {loading ? (
                    <Fragment>
                        <Cargando/>
                    </Fragment>
                 
                    ) :

                    (                        
                        datos?
                                <form name='Frmestudio' id='Frmestudio' className='row' onSubmit={enviarDatos} >
                                {
                                    error && (
                                        <div className='alert alert-danger'>
                                            {error}
                                        </div>
                                    )
                                }
                            <div className="col-md-5">
                              <label>
                                Identificación del estudio:<input 
                                name="EstCod"
                                type="text"
                                placeholder="Identificación del estudio"
                                className="form-control"
                                onChange={handleInputChange}
                                value={datos.estCod}
                                disabled="true"
                        
                        />
                    </label>
                            </div>

                            <div className="col-md-5">
                              <label>
                                Hijo:<input 
                                name="mstHijoCodInt"
                                type="text"
                                placeholder="Hijo"
                                className="form-control"
                                onChange={handleInputChange}
                                value={datos.codEstMstHijo}      
                                disabled="true"   
                        />
                        </label>
                    </div>

                    <div className="col-md-5">
                              <label>
                                Madre:<input 
                                name="mstMadreCodInt"
                                type="text"
                                placeholder="Madre"
                                className="form-control"
                                onChange={handleInputChange}
                                value={datos.codEstMstMadre}                        
                                disabled="true"
                        />
                        </label>
                    </div>
                    <div className="col-md-5">
                              <label>
                                Padre:<input 
                                name="mstPadreCodInt"
                                type="text"
                                placeholder="Padre"
                                className="form-control"
                                onChange={handleInputChange}
                                value={datos.codEstMstPadre}                        
                                disabled="true"
                        />
                        </label>
                    </div>


                <div className="col-md-4">
                    Resultado:                
                    <select className="form-control col-md-5" onChange={cambioResultadoCalculo} defaultValue={datos.estResCalcNom}> 
                        <option value="" selected="selected" hidden="hidden">{datos.estResCalcNom}</option>
                        <option value="0" >Exclusión</option>
                        <option value="1" >Inclusión</option>
                        <option value="2" >Indeterminado</option>
                    </select>                      
                </div>
                    
                <div className="col-md-4">
                    Estado del cálculo:
                    <select className="form-control col-md-5" onChange={cambioEstado} defaultValue={datos.estEstado}>  
                        <option value={datos.estEstado} selected="selected" hidden="hidden">{datos.estEstadoNom}</option>
                        <option value="P" >Pendiente de cálculo</option>
                        <option value="F" >Finalizado</option>
                        <option value="E" >Entregado al cliente</option>
                        <option value="C" >Cancelado</option>
                    </select>                      
                </div>



                    <div className="col-md-5">
                              <label>
                                Observaciones:<input 
                                name="estObservaciones"
                                type="text"
                                placeholder="Observaciones"
                                className="form-control"
                                onChange={handleInputChange}
                                value={datos.estObservaciones}                        
                        />
                    </label>
                            </div>

                            <div className="col-md-5">
                                <button 
                                className='btn btn-primary'
                                type="submit"
                                >Guardar</button>
                            </div>
                            
                        </form>
                        
                        :
                        <></>
                        )   
             }
             </Fragment>
        )
}
export default EditarEstudioFiliacion