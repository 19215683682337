import React, {Fragment, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { obtenerEspecieAccion,borrarEspecieAccion } from '../redux/especieDucks'
import Menu from '../components/menu.jsx';


const Especies = () =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const especies = useSelector(store => store.especies.array)

    useEffect (async function () {
        console.log("refrescando pantalla");
        await ActualizarEspecies();
        console.log("termine de refrescar");
       }, []);


    async function ActualizarEspecies () {
        await dispatch(obtenerEspecieAccion(filtronombre))
        console.log("actualizado")
        dispatch(obtenerEspecieAccion(filtronombre))
        
       }


    const NuevaEspecie = () => {
        navigate("/nuevaespecie");    
    }

    const eliminarElemento = (id) => {
        dispatch(borrarEspecieAccion(id))     
        navigate("/especies");         
        dispatch(obtenerEspecieAccion(""))           
    }

    const editarElemento = (id) => {
        console.log("el id")
        console.log(id);
        navigate("/editarEspecie/" + id);    
    }

    const[filtronombre, setFiltroNombre]=useState('')
    
    const handleInputChange = (evento) => {
        var todoOK;
        todoOK=true;
        var nombre = evento.target.value;
        setFiltroNombre(nombre)
    }

    const obtenerEspecies= (evento) => {
        evento.preventDefault()
        dispatch(obtenerEspecieAccion(filtronombre))
    }


    return (
        <div>            
            <Menu></Menu>
            <hr/>
            <h3>Lista de Especies</h3>
            <form className='row' onSubmit={obtenerEspecies}>
                <div className="col-md-4">
                    <input 
                        name="EspNom"
                        type="text"
                        placeholder="Nombre de la especie"
                        className="form-control"
                        onChange={handleInputChange}
                        />                    
              </div>
            <div className="col-md-5">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Buscar</button>
                    <button onClick={NuevaEspecie}
                        className='btn btn-primary'
                    >Nueva Especie</button>             
            </div>
            </form>   

<table width="100%" border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Nombre</th>
      <th scope="col" align="center">Código especie</th>
      <th scope="col" align="left">Acciones</th>
    </tr>
  </thead>
  <tbody>
{
    especies.map(item => (
<tr>
    <td>
        {item.espNombre}
    </td>
    <td>
        {item.espCod}
    </td>
    <td align="left">
    <button type="button" class="btn btn-default" onClick={() => editarElemento(item.espId)}>
    <img src='./img/pencil.svg'   />
    </button>
    <button type="button" class="btn btn-default" onClick={() => eliminarElemento(item.espId)}>
    <img src='./img/trash.svg'   />
    </button>
    </td>
</tr>
))
    }
  </tbody>
</table>

</div>
    )
}

export default Especies