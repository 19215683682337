import React, {Fragment, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { obtenerTiposMuestrasAccion,borrarTipoMuestraAccion, obtenerUnTipoMuestraAccion } from '../redux/tipoMuestraDucks'
import Menu from '../components/menu.jsx';
import { useEffect } from 'react'


const TiposMuestras = () =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tipomuestra = useSelector(store => store.tiposMuestas.array)


    useEffect (async function () {
        await ActualizarTiposDeMuestra();
       }, []);


    async function ActualizarTiposDeMuestra () {
        setTimeout(function(){
             dispatch(obtenerTiposMuestrasAccion(""));
        }, 2000);        
       }


    const NuevoTipoMuestra = () => {
        navigate("/nuevoTipoDeMuestra");    
    }

    const eliminarElemento = (id) => {
        dispatch(borrarTipoMuestraAccion(id))     
        navigate("/tiposdemuestra");          
        ActualizarTiposDeMuestra();           
    }
    
    const editarElemento = (id) => {
        navigate("/editarTipoMuestra/" + id);            
    }
    const[filtronombre, setFiltroNombre]=useState('')
    
    const handleInputChange = (evento) => {
        var todoOK;
        todoOK=true;
        var nombre = evento.target.value;
        setFiltroNombre(nombre)
    }

    const obtenerTipoMuestra= (evento) => {
        evento.preventDefault()
        dispatch(obtenerTiposMuestrasAccion(filtronombre))
    }

    return (
        <div>            
            <Menu></Menu>
            <hr/>
            <h3>Lista tipos de muestras biológicas</h3>
            <form className='row' onSubmit={obtenerTipoMuestra}>
                <div className="col-md-5">
                    <input 
                        name="ChipNom"
                        type="text"
                        placeholder="Nombre tipo de muestra"
                        className="form-control"
                        onChange={handleInputChange}
                        />                
                </div>
                <div className="col-md-5">                            
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Buscar</button>
                    <button onClick={NuevoTipoMuestra}
                        className='btn btn-primary'
                    >Nuevo tipo de muestra</button>
                </div>
            </form>                

<table  border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Nombre</th>
      <th scope="col">Detalle de Extracción</th>
      <th scope="col" align="center">Acciones</th>
    </tr>
  </thead>
  <tbody>
{
    tipomuestra.map(item => (
<tr>
    <td>
        {item.tpoMstNom}
    </td>
    <td>
        {item.tpoMstDetExt}
    </td>
    <td>
    <button type="button" class="btn btn-default" onClick={() => editarElemento(item.tpoMstId)}>
    <img src='./img/pencil.svg'   />
    </button>
    <button type="button" class="btn btn-default" onClick={() => eliminarElemento(item.tpoMstId)}>
    <img src='./img/trash.svg'   />
    </button>
    </td>

</tr>
))
    }
  </tbody>
</table>

</div>
    )
}

export default TiposMuestras