import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import validator from 'validator'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import Cargando from './loading'
import Menu from '../components/menu.jsx';

import { modificarMarcadorAccion, obtenerUnMarcadorAccion } from '../redux/marcadoresDucks'

const EditarMarcador = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [datos, setDatos] = useState(null);

    const { id } = useParams();

    const marcadores = useSelector(store => store.marcadores.unMarcador)

    useEffect(() => {
        const obtenerDatos = async () => {
            try {
                setLoading(true);
                const res = await dispatch(obtenerUnMarcadorAccion(id));
                console.log(res);
                setLoading(false);
            }
            catch (error) {
                console.log(error)
            }
        }
        obtenerDatos()
    }, []);

    useEffect(() => {
        const elMarcador = marcadores.filter(marcador => marcador.marId = id);
        if (elMarcador.length > 0)
            return setDatos(elMarcador[0]);
    }, [marcadores])

    const handleInputChange = (evento) => {
        // Asigno los valores del formulario a las variables locales de la página
        // evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
        setDatos({
            ...datos,
            [evento.target.name]: evento.target.value
        });
    };

    const enviarDatos = (evento) => {
        evento.preventDefault();
        if (!datos?.marNom.trim())
            return setError('Nombre es obligatorio');
        dispatch(modificarMarcadorAccion(id, datos));
        navigate("/marcadores");
    }

    return (
        <Fragment>
            <Menu></Menu>
            <hr />
            <h3>Editar un marcador</h3>
            {loading
                ? (
                    <Fragment>
                        <Cargando />
                    </Fragment>
                )
                : <form name='Frmmarcador' id='Frmmarcador' className='row' onSubmit={enviarDatos} >
                    {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }
                    {
                        datos
                            ? <>
                                <div className="col-md-5">
                                    <label>
                                        Nombre Marcador:<input
                                            name="marNom"
                                            type="text"
                                            placeholder="Ingrese Nombre"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            value={datos?.marNom || ''}
                                        />
                                    </label>
                                </div>
                                <p></p>
                                <div className="col-md-5">
                                    <button
                                        className='btn btn-primary'
                                        type="submit"
                                    >Guardar</button>
                                </div>
                            </>
                            : null
                    }
                </form>
            }
        </Fragment>
    )
}

export default EditarMarcador
