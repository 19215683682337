import axios from 'axios'
import React, {Fragment, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {obtenerMuestrasBiologicasAccion,borrarMuesrtaBiologica,obtenerIdMuestraBiologicaAccion} from '../redux/muestrasBiologicasDucks'
import Menu from '../components/menu.jsx';
import {CSVLink} from 'react-csv';
import entorno from '../config.js'
import DatePicker from "react-datepicker";
import { useReducer } from 'react';
const base_url=entorno("BASE_URL");

const ListaMuestrasBiologicas = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ADN,setADN]= useState();
    const [fechaIngresoInicio, setFechaIngresoInicio] = useState(null);
    const [fechaIngresoFin, setFechaIngresoFin] = useState(null);
    const [fechaExtraccionInicio, setFechaExtraccionInicio] = useState(null);
    const [fechaExtraccionFin, setFechaExtraccionFin] = useState(null);
    const[error,setError]=React.useState(null);
    const [listaEspecies,setEspecies]= useState([]);
    const [listaClientes,setClientes]= useState([]);
    const [reducerValue,forceUpdate]= useReducer (x=>x+1,0);


    const[muestra, setMuestra]=useState(
        {
            EspId:0,
            ChipId: 0,
            MstCodInt: '',
            TpoMstId: 0,
            MstFchIng: '1900-01-01',
            MstFchExt:'1900-01-01',
            MstFchNac:'1900-01-01',
            MstSexo:'',
            CliId:0,
            MstCodOrigen:'',
            MstTieneADN:'',
            MstEstado:'',
            MstActivo:false,
            MstUsrCreacion: 'vivi'
        });


        //Carga y actualizacion de los combos de seleccion de filtros
        useEffect (function () {
            axios.get(base_url + 'genexa/especies')
            .then((res) => setEspecies(res.data))
            .then ((error)=> console.log(error));
        
            axios.get(base_url + 'genexa/clientes')
            .then((response) => setClientes(response.data.value.data))
            .then ((error)=> console.log(error));

        }, []);
        //---------------------------------------------------------


        //Selección de valor de "tiene ADN"
        const SeleccionADN = [
            {
                label: "Si",
                value: "S",
            },
            {
                label: "No",
                value: "N",
            },
        ]
        //------------------------------------

        //Selección de Especie en filtros
        const seleccionEspecie = (e) =>{
            var valor=Number(e.target.value);
            setMuestra({
                ...muestra,
                EspId : valor                           
            })
            
        };
        //--------------------------------------

        //--- Selección de los clientes
        const seleccionarCliente = (e) =>{
            var valor=Number(e.target.value);
        
            setMuestra({
                ...muestra,
                CliId : valor                                       
            }) 
        };
        //----------------------------------

        //--- Selección de los clientes
        const cambioADN = (e) =>{
            setMuestra({
                ...muestra,
                MstTieneADN : e.target.value
                        
            })
            
        };
        //------------------------------------
        const [startDate, setStartDate] = useState(null);
            

        function convertDateFormat(string) {
            var info=string.substring(0,10);
            info = info.split('-');
            return info[2] + '/' + info[1] + '/' + info[0];
        }
            

        const lasmuestrasBiologicas = useSelector(store => store.muestras.array)

        const handleChangeFechaIIngreso = (dateI) => {
            setFechaIngresoInicio(dateI);            
        }; 

        const handleChangeFechaFIngreso = (dateI) => {            
        setFechaIngresoFin(dateI);
        }; 

    
        const NuevaMuestraBiologica = () => {
            navigate("/nuevaMuestraBiologica");             
        }
    
        const ImportarDesdeArchivo=()=>
        {
            navigate("/importarMuestrasArchivo");             
        }


        const eliminarElemento = async (id) => {
            dispatch(borrarMuesrtaBiologica(id)) 
            navigate("/muestras"); 
            await ActualizarMuestraBilogica();
            
        }

        
        const editarElemento = (id) => {
            navigate("/editarMuestraBiologica/" + id);    

        }

    
        const handleInputChange = (evento) => {
            setMuestra({
                ...muestra,
                [evento.target.name] : evento.target.value
            })
            
        }

        const handleBlur = (e) =>{
            handleInputChange(e); 
        } 
    

    async function ActualizarMuestraBilogica () {
        var Fecha1= '1900-01-01';
        var Fecha2='1900-01-01';
        var Fecha3='1900-01-01';
        var Fecha4='1900-01-01';       
        setTimeout(function(){
            dispatch(obtenerMuestrasBiologicasAccion(muestra,Fecha1,Fecha2,Fecha3,Fecha4))
              }, 500);        
    }

    const obtenerMuestraBiologica= async (evento) => {
        
        var fIngresoInicio;
        var fIngresoFin;
        var fExtraccionInicio;
        var fExtraccionFin;

        if (fechaIngresoInicio===null){
            
            fIngresoInicio='1900-01-01'
        }
        else {
            fIngresoInicio = fechaIngresoInicio.toLocaleDateString("en-CA")
        }

        if (fechaIngresoFin===null){
            
            fIngresoFin='1900-01-01'
        }
        else {
            fIngresoFin = fechaIngresoFin.toLocaleDateString("en-CA")
        }

            fExtraccionInicio='1900-01-01'
            fExtraccionFin= '1900-01-01'

        
       
        var todoOK;
        todoOK=true;
        var problemas='';


    if(fIngresoFin < fIngresoInicio ){
    setError ('Fecha fin ingreso debe ser mayor o igual a fecha inicio ingreso');
    todoOK=false

    }
    if (fExtraccionFin < fExtraccionInicio){
    setError ('Fecha fin extracción debe ser mayor o igual a fecha inicio extracción');
    todoOK=false
    }
    
    if (todoOK){
        console.log(muestra)
        evento.preventDefault()
        dispatch(obtenerMuestrasBiologicasAccion(muestra,fIngresoInicio,fIngresoFin,fExtraccionInicio,fExtraccionFin))
    }
    else
    {
        console.log(problemas);
    }
}

return (
<div>
    <Menu></Menu>
    <hr/>
    <h3>Lista de muestras biológicas</h3>

            <form className='row' onSubmit={obtenerMuestraBiologica}>
            {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                     }
                <div className="col-md-4">
                    <input 
                        name="MstCodInt"
                        type="text"
                        placeholder="Código Interno"
                        className="form-control"
                        onChange={handleInputChange}
                        />             
                        </div>
                  

                    <p></p>
                    <div className="col-md-4">
                    <select className="form-control col-md-5" onChange={cambioADN}>  
                  <option value="0" >Tiene ADN? (vacío = todos)</option>        
                    {SeleccionADN.map((option) => (
                     <option value={option.value}>{option.label}</option>
                        ))}
                              
                    </select>  
                    </div>
                    <div className="col-md-4">
                            <select className="form-control col-md-5" onChange={seleccionarCliente}>
                            <option value="0" >Seleccione Cliente</option>
                              {
                                listaClientes.map ((cliente) => 
                                
                                    <option key={cliente.cliId} value={cliente.cliId}>{cliente.cliNom}</option>
                                    
                                )                    
                              }
                          </select>    
                            </div>



                    
                    <div className="col-md-4">
                    <input 
                        name="MstCodOrigen"
                        type="text"
                        placeholder="Código del cliente o establecimiento"
                        className="form-control"
                        onChange={handleInputChange}
                        />   
                    </div>

                    <div className="col-md-4">
                    Fecha ingreso mayor a:
                    <DatePicker  className="form-control col-md-5"
                        type="text"
                        dateFormat="yyyy-MM-dd" 
                        placeholder ="Fecha de ingreso"
                        defaultValue={null}
                        onChange={handleChangeFechaIIngreso}
                        selected={fechaIngresoInicio}
                         onBlur={handleBlur}
                         >
                     </DatePicker>
                     </div>

                     <div className="col-md-4">
                     Fecha ingreso menor a:
                    <DatePicker  className="form-control col-md-5"
                        type="text"
                        dateFormat="yyyy-MM-dd" 
                        placeholder="Inicio fecha extracción"
                        defaultValue={null}
                        onChange={handleChangeFechaFIngreso}
                        selected={fechaIngresoFin}
                        onBlur={handleBlur}
                         >
                     </DatePicker >
                     
                    </div>
                    <p></p>
                    <table width='100%' align='center'>
                        <tr><td align='center'>
                            <button
                            className='btn btn-primary'
                            type="submit"
                            >Buscar</button>
                    </td>
                    <td align='center'>
                    <button onClick={NuevaMuestraBiologica}
                    className='btn btn-primary'
                    >Nueva Muestra</button>
                    </td>
                    <td align='center'>
                    <button onClick={ImportarDesdeArchivo}
                    className='btn btn-primary'
                    >Importar Desde Archivo</button>
                    </td>
                    <td align='center'>
                    <CSVLink data={lasmuestrasBiologicas} filename={"tablaMuestrasBiologicas.csv"}>
                            Exportar <img src='./img/csv.svg'   />
                    </CSVLink>

                    </td>
                    </tr>
                    </table>
            </form>    
<center>Se encontró/aron {lasmuestrasBiologicas.length} item/s según los filtros indicados</center>

<table width="100%" border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Código interno</th>
      <th scope="col">Cliente</th>
      <th scope="col">Especie</th>
      <th scope="col">Fecha ingreso</th>
      <th scope="col">Sexo</th>
      <th scope="col">Tiene ADN</th>
      <th scope="col">Acciones</th>
    </tr>
    </thead>
    <tbody>
    {

    lasmuestrasBiologicas.map(item => (
    <tr>
        <td>
        {item.mstCodInt}
    </td>
    <td>
        {item.cliNombre}
    </td>
    <td>
        {item.espNom}
    </td>
    <td>
        {convertDateFormat(item.mstFchIng)}
    </td>
    <td>
        {item.mstSexo}
    </td>
    <td>
        {item.mstTieneADN}
        
    </td>
    <td align="left">
        <button type="button" class="btn btn-default" onClick={() => editarElemento(item.mstId) }>
        <img src='./img/pencil.svg'   />
        </button>

        <button type="button" class="btn btn-default" onClick={() => eliminarElemento(item.mstId)}>
        <img src='./img/trash.svg'   />
        </button>
    </td>
    </tr>

))
    }
    </tbody> 
    </table>



</div>

    )
}

export default ListaMuestrasBiologicas