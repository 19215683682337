import React, {Fragment, useState, useEffect} from 'react'
import {Spinner} from "reactstrap";
import Cargando from './loading'
import { useParams } from 'react-router-dom';
import validator from 'validator'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { modificarEspecieAccion,obtenerUnaEspecieAccion } from '../redux/especieDucks'
import Menu from './menu.jsx';



const EditarEspecie =() => {

    const navigate = useNavigate();
    const dispatch = useDispatch()


    const[error,setError]=React.useState(null);
    const [loading, setLoading] = useState(false);
    const [refrescarObjeto, setrefrescarObjeto] = useState(false);
    const [listaEspecies,setEspecies]= useState([]);
    const [datos, setDatos] = useState(null);
    const { id } = useParams();
    const especies = useSelector(store => store.especies.unaEspecie)


    useEffect (() => {
        
        const obtenerDatos = async () => {            
            try
            {
                setLoading(true);
                const res = await dispatch(obtenerUnaEspecieAccion(id));               
                setLoading(false);
               
            }
            catch(error){
                console.log(error)
            }
        }
        obtenerDatos()      

    }, []);

    useEffect(() => {
        const laEspecie = especies.filter(especie => especie.espId = id);
        if (laEspecie.length > 0)
            return setDatos(laEspecie[0]);
    }, [especies])

    const handleInputChange = (evento) => {
        //Asigno los valores del formulario a las variables locales de la página
        //evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
        
        setDatos({
            ...datos,
            [evento.target.name] : evento.target.value
        })
    };


    const enviarDatos= (evento) => {
        evento.preventDefault();
            var problemas='';
            var todoOK;
            todoOK=true;

        if(!datos.espNombre.trim())
        {
            setError('Nombre especie es obligatorio');
            todoOK=false;
        }
        if(!datos.espCod.trim())
        {
            setError('Código es obligatorio');
            todoOK=false;
        }
       
        
        if(todoOK)
        {
            console.log(datos)
            dispatch(modificarEspecieAccion(id, datos));
            //asumo que todo salió ok... me voy al listado de nuevo
            navigate("/especies");  
        }
        
    }


    return (
        <Fragment>
             <Menu></Menu>
             <hr/>
             <h3>Editar una especie</h3>
             {loading ? (
                 <Fragment>
                    <Cargando/>
                </Fragment>
                ) :
                
                (
                    datos?
                            <form name='Frmcliente' id='Frmcliente' className='row' onSubmit={enviarDatos} >
                            {
                                error && (
                                    <div className='alert alert-danger'>
                                        {error}
                                    </div>
                                )
                            }
                        <div className="col-md-5">
                            <label>
                                Nombre especie:<input 
                                name="espNombre"
                                type="text"
                                placeholder="Ingrese nombre"
                                className="form-control"
                                onChange={handleInputChange}
                                value={datos?.espNombre || ''}
                                />
                            </label>
                        </div>
                        <p></p>
                        <div className="col-md-5">
                            Código especie:<input
                            name="espCod"
                            type="text" 
                            placeholder="Ingrese Codigo"
                            className="form-control"
                            onChange={handleInputChange}   
                            value={datos?.espCod || ''}       
                            />
                        </div>
                        <p></p>
                        <div className="col-md-5">
                                <button 
                                className='btn btn-primary'
                                type="submit"
                                >Guardar</button>
                            </div> 
                            <p></p>                
                    </form>
                    :
                    <></>
                    )                    
            }
        </Fragment>
    )
                

}

export default EditarEspecie