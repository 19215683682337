import React, {Fragment, useState} from 'react'
import { enviarRolAccion } from '../redux/rolesDucks'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import validator from 'validator'
import { useParams } from 'react-router-dom';
import Menu from '../components/menu.jsx';

export const Rol = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch()

    const[datos, setDatos]=useState(
    {        
        RolNombre: ''       
    });

    const[error,setError]=React.useState(null);

    const handleInputChange = (evento) => {
        setDatos({
            ...datos,
            [evento.target.name] : evento.target.value
        })
        console.log(datos);
    };

    //Algo que se dispara cuando se pierde el foco
    const handleBlur = (e) =>{
    //Disparo el evento de cambio de formulario pues ahi estan los controles
    handleInputChange(e); 
    
    }

    const enviarDatos= (evento) => {
        
        evento.preventDefault();
        var problemas='';
        var todoOK;
        todoOK=true;
        if(!datos.RolNombre.trim())
        {
            setError('Nombre del rol es obligatorio');
            todoOK=false;
        }
        
        if(todoOK)
        {
        
            dispatch(enviarRolAccion(datos));
    
            navigate("/listadoRoles");  
        }
    }

    return (
        <div className='container mt-5'>
            <Fragment>
            <Menu></Menu>
            <hr/>
            <h3>Nuevo Rol</h3>
            <form name='Frmcliente' className='row' onSubmit={enviarDatos}>

                    {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }

                <div className="col-md-5">
                    <label>
                        Nombre especie:<input 
                        name="RolNombre"
                        type="text"
                        placeholder="Ingrese nombre de Rol"
                        className="form-control"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        />
                    </label>
                </div>
                <p></p>
                <div className="col-md-5">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Guardar</button>
                </div>
                <p></p>

            </form>
            </Fragment>
        </div>
    )


}
export default Rol




    