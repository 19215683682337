import axios from 'axios'
import { useEffect } from 'react'
import React, {Fragment, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import validator from 'validator'
import { useParams } from 'react-router-dom';
import Menu from '../components/menu.jsx';
import { enviarPanelAccion } from '../redux/panelDucks'
import entorno from '../config.js'
const base_url=entorno("BASE_URL");


export const Panel = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { id } = useParams();
    
    const[datos, setDatos]=useState(
    {
        PanNombre:'',
        PanChipId:0,
        marcadores:[],
    });

    //El Chip hay que levantarlo de un combo

    //Variable que acumulará los errores encontrados de validación
const[error,setError]=React.useState(null);
const[marcadoresaenviar,setMarcadoresaEnviar]=React.useState([]);
const [listaMarcadores,setlistaMarcadores]= useState([]);
const[marcadorActual_Nom,setMarcadorActual_Nom]=React.useState(null);
const [listaChips,setChips]= useState([]);

useEffect (function () {
  
    //Busco y cargo el array con todos los marcadores
    axios.get(base_url +'genexa/marcadores')
    .then((res) => setlistaMarcadores(res.data.value.data))
    .then ((error)=> console.log(error));

    axios.get(base_url +'genexa/chips')
    .then((res) => setChips(res.data))
    .then ((error)=> console.log(error));
}, []);


useEffect (function () {
    setDatos({
        ...datos,
        marcadores : marcadoresaenviar
        
    })
    
}, [marcadoresaenviar]);



const handleInputChange = (evento) => {
    setDatos({
        ...datos,
        [evento.target.name] : evento.target.value
    })
};


const seleccionMarcadorActual = (e) =>{
    setMarcadorActual_Nom(e.target.value);
};

const seleccionChip = (e) =>{
    var valor=Number(e.target.value);

    setDatos({
        ...datos,
        PanChipId : valor
       
        
    })
    
};

const eliminarElemento = (nombreMarcador) => {
    const nuevoMarcadores=marcadoresaenviar.filter((marcador) => marcador.nombreMarcador !== nombreMarcador)
    setMarcadoresaEnviar(nuevoMarcadores);
}


const addNewMarcador = () => {

    //Ya esta?
    var yaestaba=false;
    marcadoresaenviar.forEach(element =>       {      
        if(element.nombreMarcador===marcadorActual_Nom)
          yaestaba=true;
        }   
    );

    if(!yaestaba)
    {
        // listaMarcadores.forEach(element =>       {      
        //     if(element.marNom===marcadorActual_Nom)
        //     setMarcadorActual_Id(element.marId) 
        //     }   
        // );

        // const nuevomarcador ={
        //     idMarcador:marcadorActual_Id,
        //     nombreMarcador: marcadorActual_Nom
        // }

        const nuevomarcador ={            
            nombreMarcador: marcadorActual_Nom
        }
        console.log(nuevomarcador);
        setMarcadoresaEnviar([
            ...marcadoresaenviar,nuevomarcador
        ]);

        setError('');

        


    }
    else
    {
        setError('El marcador ya se encuentra en el listado');
    }
};

const enviarDatos= (evento) => {
    evento.preventDefault();
    var problemas='';
    var todoOK;
    todoOK=true;
    if(!datos.PanNombre.trim())
    {
        setError('Nombre del panel es obligatorio');
        todoOK=false;
    }

    if(datos.PanChipId===0)
    {
        setError('Campo chip es obligatorio');
        todoOK=false;
    }
    
    if(marcadoresaenviar.length==0)
    {
        setError('El panel debe tener al menos 1 (un) marcador asignado');
        todoOK=false;
    }




    if(todoOK)
    {
    
        //console.log(datos);

        dispatch(enviarPanelAccion(datos));

        //asumo que todo salió ok... me voy al listado de nuevo
        navigate("/paneles");  
    }

    

};  

    return (
        <div className='container mt-5'>
            <Fragment>
            <Menu></Menu>
            <hr/>
            <h3>Nuevo Panel</h3>
            <form name='FrmPanel' className='row' onSubmit={enviarDatos}>
                    {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }

                <div className="col-md-5">
                    <label>
                        Nombre Panel:<input 
                        name="PanNombre"
                        type="text"
                        placeholder="Ingrese nombre de panel"
                        className="form-control"
                        onChange={handleInputChange}
                        />
                    </label>
                </div>
                <p>
 
                </p>

                <div className="col-md-5">
                      Marcador:
                      <select className="form-control col-md-5" onChange={seleccionMarcadorActual}>
                          <option value="0" >Seleccione Marcador</option>
                              {
                                listaMarcadores.map ((marcador) => 
                                    <option  value={marcador.marNom}>{marcador.marNom}</option>                                    
                                )                    
                              }
                          </select>  
                          <button onClick={addNewMarcador}
                type='button'
                        className='btn btn-primary'
                    >Agregar Marcador</button>                    
                  </div>
                
                  <div className="col-md-5">
                      Chip:
                      <select className="form-control col-md-5" onChange={seleccionChip}>
                          <option value="0" >Seleccione chip</option>
                              {
                                listaChips.map ((chip) => 
                                
                                    <option key={chip.chipId} value={chip.chipId}>{chip.chipNom}</option>
                                    
                                )                    
                              }
                          </select>  
                      
                  </div>


                  <table width="100%" border="1" class="table table-striped">

                        <thead class="thead-dark">
                            <tr>
                            <th scope="col">Marcadores seleccionados</th>
                            <th scope="col" align="center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                {
                    marcadoresaenviar.map(marcador => (
                <tr>
                    <td>
                        {marcador.nombreMarcador}
                    </td>
                    <td>
                    <button type="button" class="btn btn-default" onClick={() => eliminarElemento(marcador.nombreMarcador)}>
                    <img src='./img/trash.svg'   />
                    </button>
                    </td>
                </tr>
                ))
                    }
                </tbody>
                </table>
                <div className="col-md-5">
                       <button 
                       className='btn btn-primary'
                       type="submit"
                       >Guardar panel y marcadores</button>
                   </div>

            </form>
            </Fragment>
        </div>
    )



}
export default Panel