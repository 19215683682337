import axios from 'axios'
import entorno from '../config.js'
const base_url=entorno("BASE_URL");

// constantes
// constantes
const dataInicial = {
    array : [],
    unChip : [],
    offset: 0
}

// types
const OBTENER_CHIP_EXITO = 'OBTENER_CHIP_EXITO'
const ENVIAR_CHIP_EXITO = 'ENVIAR_CHIP_EXITO'
const OBTENER_UN_CHIP_EXITO = 'OBTENER_UN_CHIP_EXITO'
const MODIFICAR_CHIP_EXITO = 'MODIFICAR_CHIP_EXITO'

// reducer
export default function chipReducer(state = dataInicial, action){
    switch(action.type){
        case OBTENER_CHIP_EXITO:
            return {...state, array: action.payload}
        case OBTENER_UN_CHIP_EXITO:
            return {...state, unChip: action.payload}
        default:
            return state
    }
}


// acciones
export const obtenerChipAccion = (nombre) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(nombre.trim())
        {
            filtro='/'+nombre;
        }
        var direc=base_url + 'genexa/chips' + filtro;
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_CHIP_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}


export const obtenerUnChipAccion = (id) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(id.trim())
        {
            filtro='/'+id;
        }
        var direc=base_url + 'genexa/chips' + filtro;
        
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_UN_CHIP_EXITO,
            payload: res.data
        })        
    } catch (error) {
        console.log(error)
    }
}

export const enviarChipAccion = (datos) => async (dispatch, getState) => {
console.log("envio chip")
    try {
        const res = await axios.post(base_url + 'genexa/chips',datos)
        dispatch({
            type: ENVIAR_CHIP_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const borrarChipAccion = (id) => async (dispatch, getState) => {
console.log("entro borrar")
console.log(id)
    try {
        console.log(id)

        const res = await axios.delete(base_url + 'genexa/chips/'+id)
        dispatch({
            type: ENVIAR_CHIP_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const modificarChipAccion = (id,datos) => async (dispatch, getState) => {

    const chipModificado = {
        ChipNom:datos.chipNom,
        ChipFabricante:datos.chipFabricante,
        ChipActivo:datos.chipActivo,
        ChipUsrCreacion: "Admin"
    };

    console.log ("Entro en funcion de put de chip")
    try {
        console.log(id)
        console.log (chipModificado)        
        const res = await axios.put(base_url + 'genexa/chips/'+id,chipModificado)
        dispatch({
            type: MODIFICAR_CHIP_EXITO,
            payload: res.data
        })

    } catch (error) {
        console.log("Error de envio de datos");
        console.log(error)
    }
}