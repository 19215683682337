import React, {Fragment, useState} from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { obtenerMarcadoresAccion, modificarMarcadorAccion, borrarMarcadorAccion} from '../redux/marcadoresDucks'
import Menu from '../components/menu.jsx';

const Marcadores = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
   
    const marcadores = useSelector(store => store.marcadores.array)

    useEffect (async function () {
        const timer = setTimeout(() => {
        ActualizarMarcadores();
        }, 1000);
        return () => clearTimeout(timer);
       }, []);


    async function ActualizarMarcadores () {
        await dispatch(obtenerMarcadoresAccion(filtromarcador))
        var retorno;
        dispatch(obtenerMarcadoresAccion(filtromarcador,retorno))
       }

    const NuevoMarcador = () => {
        navigate("/nuevoMarcador");            
    }

    const eliminarElemento = async (id) => {
        dispatch(borrarMarcadorAccion(id))      
        await ActualizarMarcadores();
    }
    
    const editarElemento = (id) => {
        navigate("/editarMarcador/" + id);            
    }

    const[filtromarcador, setFiltroMarcador]=useState('')
    
    const handleInputChange = (evento) => {
        var todoOK;
        todoOK=true;
        var nombre = evento.target.value;
        setFiltroMarcador(nombre)
    }

const obtenerMarcadores= (evento) => {
    evento.preventDefault()
    console.log("Tratando de obtener");
    dispatch(obtenerMarcadoresAccion(filtromarcador))
}

   return (
        <div>
            <Menu></Menu>
            <hr/>
            <h3>Lista de Marcadores</h3>
            <form className='row' onSubmit={obtenerMarcadores}>
                <div className="col-md-4">
                    <input 
                        name="MarNom"
                        type="text"
                        placeholder="Ingrese Nombre"
                        className="form-control"
                        onChange={handleInputChange}
                        />
                </div>                
                <div className="col-md-5">                            
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Buscar</button>
                    <button onClick={NuevoMarcador}
                        className='btn btn-primary'
                    >Nuevo Marcador</button>
                </div>
            </form>                

<table width="100%" border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Nombre</th>
      {/* <th scope="col">Fecha</th>
      <th scope="col">Teléfono</th> */}
      <th scope="col" align="center"> Acciones </th>
    </tr>
  </thead>
  <tbody>
{
    marcadores.map(item => (
<tr>
    <td>
        {item.marNom}
    </td>
    <td align="center">
    <button type="button" class="btn btn-default" onClick={() => editarElemento(item.marId)}>
    <img src='./img/pencil.svg'   />
    </button>
    <button type="button" class="btn btn-default" onClick={() => eliminarElemento(item.marId)}>
    <img src='./img/trash.svg'   />
    </button>
    </td>
</tr>

    ))
    }


</tbody>
</table>

        </div>
    )
}

export default Marcadores