
import entorno from '../config.js'
const base_url=entorno("BASE_URL");

// constantes
const dataInicial = {
    unusuario : [],
    offset: 0
}

// types
const LOGIN_USUARIO_EXITO = 'LOGIN_USUARIO_EXITO'


// reducer
export default function loginReducer(state = dataInicial, action){
    switch(action.type){
        case LOGIN_USUARIO_EXITO:
            return {...state, unusuario: action.payload}
        default:
            return state
    }
}


export const LoginUsuarioAccion = (usuario,clave) => async (dispatch, getState) => {

    try {

        const params = {
                UsrUsuario: usuario,
                UsrClave: clave
        };

        //var direc=base_url + 'genexa/clientes' + filtro;
        console.log(params);
       
    } catch (error) {
        console.log(error)
    }
}
