import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/Login.css';
import sha256 from 'crypto-js/sha256';
import Cookies from 'universal-cookie';
import entorno from '../config.js'

const base_url = entorno("BASE_URL");
const cookies = new Cookies();

//VIDEO DE LOGIN
//https://www.youtube.com/watch?v=JKenEw4S5BQ

const Login = () => {
    const navigation = useNavigate();

    const [username, setUsername] = useState(''),
        [password, setPassword] = useState('');


    const enviarDatos = (evento) => {
        evento.preventDefault();

        if (username && password) {
            var claveEncriptada = sha256(password).toString();
            const credenciales = {
                UsrUsuario: username,
                UsrClave: claveEncriptada
            }
            Loguearse(credenciales)
        } else {
            alert("Debe indicar usuario y clave");
        }
    }

    const Loguearse = async (credenciales) => {
        var direc = base_url + 'genexa/seguridad/usuarios/login';
        console.log(direc);
        const response = await axios.post(direc, credenciales);
        console.log(response);
        if (response.data.length > 0) {
            console.log(response.data[0].usrNombreCompleto);
            var respuesta = response.data[0];
            console.log(respuesta);
            cookies.set('usrId', respuesta.usrId, { path: "/" });
            cookies.set('usrUsuario', respuesta.usrUsuario, { path: "/" });
            cookies.set('usrNombreCompleto', respuesta.usrNombreCompleto, { path: "/" });
            cookies.set('usrRolUsuario', respuesta.usrRolUsuario, { path: "/" });
            //alert("Bienvenido " + respuesta.usrNombreCompleto);
            navigation('/home');
        } else
            alert("Usuario inexistente");
    }

    useEffect(() => {
        if (cookies.get('usrNombreCompleto')) {
            // window.location.href = "./home";
            navigation('/home');
        }
    }, []);

    return (
        <div className="containerPrincipal">
            <div className="containerSecundario">
                <form name='FrmLogin' className='row' onSubmit={enviarDatos}>
                    <img src='../img/logo.jpg' width="10%"></img>
                    <div className="form-group">
                        <label>Usuario: </label>
                        <br />
                        <input
                            type="text"
                            className="form-control"
                            name="username"
                            onChange={({ target: { value } }) => setUsername(value)}
                        />

                        <label>Contraseña: </label>
                        <br />
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            onChange={({ target: { value } }) => setPassword(value)}
                        />
                        <br />
                        <button
                            className='btn btn-primary'
                            type="submit"
                        >Iniciar Sesión</button>
                    </div>
                </form>
                <center>sgs-filiaciones v.1.4 - Julio 2024</center>
            </div>
        </div>
    )
}

export default Login;
