import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import validator from 'validator'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import entorno from '../config.js'
import Cargando from './loading'
import Menu from '../components/menu.jsx';

import { obtenerUnUsuarioAccion,modificarUsuarioAccion } from '../redux/usuariosDucks'

const base_url=entorno("BASE_URL");

const EditarUsuario = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [listaRoles,setRoles]= useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [datos, setDatos] = useState(null);

    const { id } = useParams();

    const usuarios = useSelector(store => store.usuarios.unUsuario)

    useEffect(() => {
        const obtenerDatos = async () => {
            try {
                setLoading(true);
                const res = await dispatch(obtenerUnUsuarioAccion(id));

                axios.get(base_url + 'genexa/seguridad/roles')
                .then((res) => setRoles(res.data))
                .then ((error)=> console.log(error));
                setLoading(false);
            }
            catch (error) {
                console.log(error)
            }
        }
        obtenerDatos()
    }, []);

    useEffect(() => {
        const elUsuario = usuarios.filter(usuario => usuario.usrId = id);
        if (elUsuario.length > 0)
            return setDatos(elUsuario[0]);
    }, [usuarios])

    const handleInputChange = (evento) => {
        // Asigno los valores del formulario a las variables locales de la página
        // evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
        setDatos({
            ...datos,
            [evento.target.name]: evento.target.value
        });
    };

    
    const cargarDatosRol= (rolId) => {
     console.log(rolId);     
    }

    const seleccionarRol = (e) =>{
        var valor=Number(e.target.value);
        console.log("cambio de rol");
        console.log(e.target.value)

        setDatos({
            ...datos,
            usrRolUsuario : valor
        })
    };

    const enviarDatos = (evento) => {
        evento.preventDefault();
        var problemas='';
        var todoOK;
        todoOK=true;
        console.log(datos);

         if (!datos?.usrUsuario.trim())
             return setError('Nombre de usuario obligatorio');

         if (!datos?.usrNombreCompleto.trim())
             return setError('Nombre completo obligatorio');

        if (datos?.usrCorreoUsuario.trim()) {
            var email = datos?.usrCorreoUsuario;
            if (!validator.isEmail(email))
                return setError('El formato del email ingresado no es correcto');
        }
        console.log(datos);
        dispatch(modificarUsuarioAccion(id, datos));
        navigate("/listadoUsuarios");
    }

    return (
        <Fragment>
            <Menu></Menu>
            <hr />
            <h3>Editar un usuario</h3>
            {loading
                ? (
                    <Fragment>
                        <Cargando />
                    </Fragment>
                )
                : <form name='Frmusuario' id='Frmusuario' className='row' onSubmit={enviarDatos} >
                    {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }
                    {
                        datos
                            ? <>
                                <div className="col-md-5">
                                    <label>
                                        Usuario:<input
                                            name="usrUsuario"
                                            type="text"
                                            placeholder="Ingrese Nombre"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            value={datos?.usrUsuario || ''}
                                        />
                                    </label>
                                </div>

                                <div className="col-md-5">
                                    <label>
                                        Nombre completo:<input
                                            name="usrNombreCompleto"
                                            type="text"
                                            placeholder="Nombre completo"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            value={datos?.usrNombreCompleto || ''}
                                        />
                                    </label>
                                </div>

                                <div className="col-md-5">
                                    <label>
                                        Correo electrónico:<input
                                            name="usrCorreoUsuario"
                                            type="text"
                                            placeholder="Correo electrónico"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            value={datos?.usrCorreoUsuario || ''}
                                        />
                                    </label>
                                </div>


                                <div className="col-md-5">
                                Rol:
                                <select className="form-control col-md-5" value={datos.usrRolUsuario} onChange={seleccionarRol}>
                                    <option value="0" >Seleccione Rol</option>
                                        {
                                            listaRoles.map ((rol) => 
                                                <option key={rol.rolId} value={rol.rolId}>{rol.rolNombre}</option>
                                            )                    
                                        }
                                    </select>  
                                </div>

                                <div className="col-md-5">
                                    <button
                                        className='btn btn-primary'
                                        type="submit"
                                    >Guardar</button>
                                </div>
                            </>
                            : null
                    }
                </form>
            }
        </Fragment>
    )
}

export default EditarUsuario
