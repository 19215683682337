import axios from 'axios'
import entorno from '../config.js'
const base_url=entorno("BASE_URL");

// constantes
const dataInicial = {
    array : [],
    offset: 0
}

// types
const OBTENER_ARCHIVOS_EXITO = 'OBTENER_ARCHIVOS_EXITO'
const ELIMINAR_ARCHIVO_EXITO = 'ELIMINAR_ARCHIVO_EXITO'
const FSABD_ARCHIVO_EXITO = 'FSABD_ARCHIVO_EXITO'
const GENERARPERFILES_ARCHIVO_EXITO = 'GENERARPERFILES_ARCHIVO_EXITO'

// reducer
export default function archivoReducer(state = dataInicial, action){
    switch(action.type){
        case OBTENER_ARCHIVOS_EXITO:
            return {...state, array: action.payload}     
        case ELIMINAR_ARCHIVO_EXITO:
            return {...state, array: action.payload}     
        case FSABD_ARCHIVO_EXITO:
            return {...state, array: action.payload}    
        case GENERARPERFILES_ARCHIVO_EXITO:
            return {...state, array: action.payload}                                             
        default:
            return state
    }
}

// acciones
export const obtenerArchivosAccion = (Estado) => async (dispatch, getState) => {

    try {
        var direc=base_url + 'genexa/archivos/estado/' + Estado;
        console.log(direc);
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_ARCHIVOS_EXITO,
            payload: res.data
        })

    } catch (error) {
        console.log(error)
    }
}

export const borrarArchivosAccion = (id) => async (dispatch, getState) => {
console.log(id);
    try {
        const res = await axios.delete(base_url + 'genexa/archivos/'+id)
        dispatch({
            type: ELIMINAR_ARCHIVO_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const defsabdAccion = (id) => async (dispatch, getState) => {
    console.log(id);
        try {
            const res = await axios.get(base_url + 'genexa/archivos/procesar/'+id)
            dispatch({
                type: FSABD_ARCHIVO_EXITO,
                payload: res.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    export const generarPerfilesAccion = (id) => async (dispatch, getState) => {
        console.log(id);
            try {
                const res = await axios.get(base_url + 'genexa/archivos/generarperfiles/'+id)
                dispatch({
                    type: GENERARPERFILES_ARCHIVO_EXITO,
                    payload: res.data
                })
            } catch (error) {
                console.log(error)
            }
        }


