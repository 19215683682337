import axios from 'axios'
import entorno from '../config.js'
const base_url=entorno("BASE_URL");

// constantes
// constantes
const dataInicial = {
    array : [],
    unaEspecie : [],
    offset: 0
}

// types
const OBTENER_ESPECIE_EXITO = 'OBTENER_ESPECIE_EXITO'
const ENVIAR_ESPECIE_EXITO = 'ENVIAR_ESPECIE_EXITO'
const OBTENER_UNA_ESPECIE_EXITO = 'OBTENER_UNA_ESPECIE_EXITO'
const MODIFICAR_ESPECIE_EXITO = 'MODIFICAR_ESPECIE_EXITO'

// reducer
export default function especieReducer(state = dataInicial, action){
    switch(action.type){
        case OBTENER_ESPECIE_EXITO:
            return {...state, array: action.payload}
        case OBTENER_UNA_ESPECIE_EXITO:
            return {...state, unaEspecie: action.payload}
        default:
            return state
    }
}


// acciones
export const obtenerEspecieAccion = (nombre) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(nombre.trim())
        {
            filtro='/'+nombre;
        }
        var direc=base_url + 'genexa/especies' + filtro;
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_ESPECIE_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}


export const obtenerUnaEspecieAccion = (id) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(id.trim())
        {
            filtro='/'+id;
        }
        var direc=base_url + 'genexa/especies' + filtro;
        
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_UNA_ESPECIE_EXITO,
            payload: res.data
        })        
    } catch (error) {
        console.log(error)
    }
}

export const enviarEspecieAccion = (datos) => async (dispatch, getState) => {

    try {
        const res = await axios.post(base_url + 'genexa/especies',datos)
        dispatch({
            type: ENVIAR_ESPECIE_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const borrarEspecieAccion = (id) => async (dispatch, getState) => {
console.log("entro borrar")
console.log(id)
    try {
        console.log(id)

        const res = await axios.delete(base_url + 'genexa/especies/'+id)
        dispatch({
            type: ENVIAR_ESPECIE_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const modificarEspecieAccion = (id,datos) => async (dispatch, getState) => {

    const especieModificado = {

        EspId:datos.EspId,
        EspCod: datos.espCod,
        EspNombre: datos.espNombre,
        EspActivo : true,
        EspUsrActualizacion : 'admin'
    };

    console.log ("Entro en funcion de put de chip")
    try {
        console.log(id)
        console.log (especieModificado)        
        const res = await axios.put(base_url + 'genexa/especies/'+id,especieModificado)
        dispatch({
            type: MODIFICAR_ESPECIE_EXITO,
            payload: res.data
        })

    } catch (error) {
        console.log("Error de envio de datos");
        console.log(error)
    }
}