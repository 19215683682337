import React, {useState} from 'react';
import '../css/Routes.css';

import Home from '../components/home'
import ListaClientes from '../components/listaclientes';
import Cliente from '../components/cliente';
import EditarCliente from '../components/editarCliente';

import ListaChips from '../components/listaChips'
import Chip from '../components/chip';
import EditarChip from '../components/editarchip'

import ListaMarcadores from '../components/listaMarcadores'
import Marcador from '../components/marcador';
import EditarMarcador from '../components/editarMarcador'

import Especie from '../components/especie';
import EditarEspecie from '../components/editarEspecie';
import ListaEspecies from '../components/listaEspecies';

import EditarMuestraBiologica from '../components/editarMuestraBiologica';
import EditarEstudioFiliacion from '../components/editarEstudioFiliacion';

import ListaPaneles from '../components/listapaneles';
import NuevoPanel from '../components/nuevopanel';

import ListaMuestrasBiologicas from '../components/listaMuestrasBiologicas';
import MuestraBiologica from '../components/muestraBiologica';
import ImportarMuestrasBiologicas from '../components/importarMuestrasArchivo';


import ListaTiposDeMuestras from '../components/listaTiposMuestras';
import ListaEstudiosFiliaciones from '../components/listaEstudiosFiliaciones';
import EstudioFiliacion from '../components/estudioFiliacion';
import TipoDeMuestra from '../components/tipoMuestra';

import EditarTipoMuestra from '../components/editarTipoMuestra'
import UploadFileSnp from '../components/uploadFileSNP';
import ListaArchivos from '../components/listaArchivos';

import ListadoTareas from '../components/listadoTareas';
import ListadoRoles from '../components/listadoRoles';
import NuevoRol from '../components/nuevorol';
import ListadoUsuarios from '../components/listadoUsuarios';
import NuevoUsuario from '../components/nuevousuario';
import CambioClave from '../components/cambioclave'
import EditarUsuario from '../components/editarUsuario';

import EstudioFiliacionReporte from '../components/estudiofiliacionreporte';


import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {Provider} from 'react-redux'
import generateStore from '../redux/store'
import Login from '../pages/Login';

function Rutas() {

  //Trae todo el store - el almacen de datos
  const store=generateStore()

  return (
    <Provider store={store}>
    
  <Router>
    <div className="container">
        <Routes>
          <Route exact path="/" caseSensitive={false} element={<Login />}/>
          <Route exact path="/home" caseSensitive={false} element={<Home />}/>

          <Route path="/clientes" caseSensitive={false} element={<ListaClientes />}/>
          <Route path="/fichacliente/:id" caseSensitive={false} element={<Cliente />}/>
          <Route path="/editarCliente/:id" caseSensitive={false} element={<EditarCliente />}/>

          <Route path="/paneles" caseSensitive={false} element={<ListaPaneles />}/> 
          <Route path="/nuevoPanel" caseSensitive={false} element={<NuevoPanel />}/>

          <Route path="/muestras" caseSensitive={false} element={<ListaMuestrasBiologicas />}/>
          <Route path="/nuevaMuestraBiologica" caseSensitive={false} element={<MuestraBiologica />}/>
          <Route path="/editarMuestraBiologica/:id" caseSensitive={false} element={<EditarMuestraBiologica />}/>
          <Route path="/importarMuestrasArchivo" caseSensitive={false} element={<ImportarMuestrasBiologicas />}/>

          <Route path="/especies" caseSensitive={false} element={<ListaEspecies />}/>
          <Route path="/nuevaEspecie" caseSensitive={false} element={<Especie />}/>
          <Route path="/editarEspecie/:id" caseSensitive={false} element={<EditarEspecie/>}/>

          <Route path="/marcadores" caseSensitive={false} element={<ListaMarcadores />}/>
          <Route path="/nuevoMarcador" caseSensitive={false} element={<Marcador />}/>
          <Route path="/editarMarcador/:id" caseSensitive={false} element={<EditarMarcador/>}/>

          <Route path="/chips" caseSensitive={false} element={<ListaChips />}/>
          <Route path="/nuevoChip" caseSensitive={false} element={<Chip />}/>
          <Route path="/editarChip/:id" caseSensitive={false} element={<EditarChip />}/>

          <Route path="/editarEstudioFiliacion/:id" caseSensitive={false} element={<EditarEstudioFiliacion />}/>
          <Route path="/editarTipoMuestra/:id" caseSensitive={false} element={<EditarTipoMuestra />}/>
          <Route path="/fichaMuestraBiologica/:id" caseSensitive={false} element={<MuestraBiologica />}/>

          <Route path="/tiposdemuestra" caseSensitive={false} element={<ListaTiposDeMuestras />}/>
          <Route path="/nuevoTipoDeMuestra" caseSensitive={false} element={<TipoDeMuestra />}/>

          <Route path="/archivos" caseSensitive={false} element={<ListaArchivos />}/>
          <Route path="/subirArchivoSNP/:id" caseSensitive={false} element={<UploadFileSnp />}/>

          <Route path="/listado" caseSensitive={false} element={<ListaEstudiosFiliaciones />}/>
          <Route path="/nuevoEstudioFiliacion" caseSensitive={false} element={<EstudioFiliacion />}/>
          <Route path="/estudiofiliacionreporte/:id" caseSensitive={false} element={<EstudioFiliacionReporte />}/>

          <Route path="/listadoTareas" caseSensitive={false} element={<ListadoTareas />}/>
          <Route path="/listadoRoles" caseSensitive={false} element={<ListadoRoles />}/>
          <Route path="/nuevoRol" caseSensitive={false} element={<NuevoRol />}/>
          <Route path="/listadoUsuarios" caseSensitive={false} element={<ListadoUsuarios />}/>
          <Route path="/nuevoUsuario" caseSensitive={false} element={<NuevoUsuario />}/>
          <Route path="/cambioClave" caseSensitive={false} element={<CambioClave />}/>
          <Route path="/editarUsuario/:id" caseSensitive={false} element={<EditarUsuario />}/>

        </Routes>    
  </div>
  </Router>
  </Provider>
  );
}
export default Rutas;
