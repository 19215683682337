import React, {Fragment, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { obtenerChipAccion,borrarChipAccion } from '../redux/chipDucks'
import Menu from '../components/menu.jsx';
import { useEffect } from 'react'

const Chips = () =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const chips = useSelector(store => store.chips.array)


    useEffect (async function () {
        console.log("refrescando pantalla");
        await ActualizarChips();
        console.log("termine de refrescar");
       }, []);


       async function ActualizarChips () {
        await dispatch(obtenerChipAccion(""))
        console.log("actualizado")
        dispatch(obtenerChipAccion(""))
       }




    const NuevoChip = () => {
        navigate("/nuevoChip");    
    }

    const eliminarElemento = (id) => {
        dispatch(borrarChipAccion(id))   
          navigate("/chips");        
          ActualizarChips();            
    }
    
    const editarElemento = (id) => {
        console.log("el id")
        console.log(id);
        navigate("/editarChip/" + id);            
    }
    const[filtronombre, setFiltroNombre]=useState('')
    
    const handleInputChange = (evento) => {
        var todoOK;
        todoOK=true;
        var nombre = evento.target.value;
        setFiltroNombre(nombre)
    }

    const obtenerChip= (evento) => {
        evento.preventDefault()
        dispatch(obtenerChipAccion(filtronombre))
    }

    return (
        <div>            
            <Menu></Menu>
            <hr/>
            <h3>Lista de chips</h3>
            <form className='row' onSubmit={obtenerChip}>
                <div className="col-md-4">
                    <input 
                        name="ChipNom"
                        type="text"
                        placeholder="Ingrese nombre Chip"
                        className="form-control"
                        onChange={handleInputChange}
                        />                    
                </div>
                <div className="col-md-5">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Buscar</button>
                    <button onClick={NuevoChip}
                        className='btn btn-primary'
                    >Nuevo Chip</button>
                </div>
            </form>                

<table width="100%" border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Nombre</th>
      <th scope="col">Fabricante</th>
      <th scope="col" align="center">Acciones</th>
      {/* {<th scope="col">Acciones</th>} */}
    </tr>
  </thead>
  <tbody>
{
    chips.map(item => (
<tr>
    <td>
        {item.chipNom}
    </td>
    <td>
        {item.chipFabricante}
    </td>
    {/* <td align="center"> */}
    <button type="button" class="btn btn-default" onClick={() => editarElemento(item.chipId)}>
    <img src='./img/pencil.svg'/>
    </button>
    <button type="button" class="btn btn-default" onClick={() => eliminarElemento(item.chipId)}>
    <img src='./img/trash.svg'/>
    </button>
    {/* </td> */}
</tr>
))
    }
  </tbody>
</table>

</div>
    )
}

export default Chips