import axios from 'axios'
import entorno from '../config.js'
import Cookies from 'universal-cookie';

const base_url=entorno("BASE_URL");
const cookies = new Cookies();


// constantes
const dataInicial = {
    array : [],
    offset: 0
}

// types

const OBTENER_TAREAS_EXITO = 'OBTENER_TAREAS_EXITO'


// reducer
export default function tareaReducer(state = dataInicial, action){
    switch(action.type){
         case OBTENER_TAREAS_EXITO:
            return {...state, array: action.payload}
        default:
            return state
    }
}

// acciones
export const obtenerTareasAccion = (nombre) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(nombre.trim())
        {
            filtro='/'+nombre;
        }
        
        var direc=base_url + 'genexa/seguridad/tareas' + filtro;
        console.log(direc);
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_TAREAS_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}