import axios from 'axios'
import entorno from '../config.js'
const base_url=entorno("BASE_URL");

// constantes
const dataInicial = {
    array : [],
    unTipoMuestra : [],
    offset: 0
}

// types
const OBTENER_TIPOSMUESTRAS_EXITO = 'OBTENER_TIPOSMUESTRAS_EXITO'
const ENVIAR_TIPOMUESTRA_EXITO = 'ENVIAR_TIPOMUESTRA_EXITO'
const OBTENER_UN_TIPOMUESTRA_EXITO = 'OBTENER_UN_TIPOMUESTRA_EXITO'
const MODIFICAR_TIPOMUESTRA_EXITO = 'MODIFICAR_TIPOMUESTRA_EXITO'


// reducer
export default function tipoMuestraReducer(state = dataInicial, action){
    switch(action.type){
        case OBTENER_TIPOSMUESTRAS_EXITO:
            return {...state, array: action.payload}
        case OBTENER_UN_TIPOMUESTRA_EXITO:
            return {...state, unTipoMuestra: action.payload}
        default:
            return state
    }
}

// acciones
export const obtenerTiposMuestrasAccion = (nombre) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(nombre.trim())
        {
            filtro='/'+nombre;
        }
        var direc=base_url + 'genexa/tipodemuestras' + filtro;
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_TIPOSMUESTRAS_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const obtenerUnTipoMuestraAccion = (id) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(id.trim())
        {
            filtro='/'+id;
        }
        var direc=base_url + 'genexa/tipodemuestras' + filtro;
        
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_UN_TIPOMUESTRA_EXITO,
            payload: res.data
        })        
    } catch (error) {
        console.log(error)
    }
}

export const enviarTipoMuestraAccion = (datos) => async (dispatch, getState) => {
    console.log("envio tipo muestra")
console.log(datos)
    try {
        const res = await axios.post(base_url + 'genexa/tipodemuestras',datos)
        dispatch({
            type: ENVIAR_TIPOMUESTRA_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const borrarTipoMuestraAccion = (id) => async (dispatch, getState) => {

    try {
        const res = await axios.delete(base_url + 'genexa/tipodemuestras/'+id)
        dispatch({
            type: ENVIAR_TIPOMUESTRA_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}


export const modificarTipoMuestraAccion = (id,datos) => async (dispatch, getState) => {

    const tipoMuestraModificado = {
        TpoMstNom:datos.tpoMstNom,
        TpoMstDetExt:datos.tpoMstDetExt,
        TpoMstActivo:datos.tpoMstActivo,
        //TpoMsUsrCreacion:"Admin",
        TpoMsUsrActualizacion:"Admin"
    };

    console.log ("Entro en funcion de put de tipo de muestra")
    try {
        console.log(id)
        console.log (tipoMuestraModificado)        
        const res = await axios.put(base_url + 'genexa/tipodemuestras/'+id,tipoMuestraModificado)
        dispatch({
            type: MODIFICAR_TIPOMUESTRA_EXITO,
            payload: res.data
        })

    } catch (error) {
        console.log("Error de envio de datos");
        console.log(error)
    }
}
