import React, {Fragment, useState} from 'react'
import { enviarClienteAccion,obtenerUnClienteAccion } from '../redux/clienteDucks'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import validator from 'validator'
import { useParams } from 'react-router-dom';
import Menu from '../components/menu.jsx';

export const Cliente = () => {

const navigate = useNavigate();
const dispatch = useDispatch()

const { id } = useParams();

const[datos, setDatos]=useState(
{
    CliId:0,
    CliNom:'',
    CliDir: '',
    CliTel: '',
    CliEmail: '',
    CliCntNom: '',
    CliCntTel:'',
    CliRazSoc:'',
    CliRut:''
});

//Variable que acumulará los errores encontrados de validación
const[error,setError]=React.useState(null);
const[clienteId,setClienteId]=React.useState(0);

const[clienteNom,setClienteNom]=React.useState('');

const [obtenerCliente,setObtenerCliente]= useState([]);

const clientes = useSelector(store => store.clientes.array)

if(id>0&&clienteId==0)
{
    setClienteId(id);
}

function traerDatosCliente () {
    if(clienteId>0&clienteId<999)
    {
        console.log("trayendo datos del id correspondiente " + clienteId)    
        dispatch(obtenerUnClienteAccion(clienteId));
        //console.log(clientes)
        var CliNom=''
        clientes.map(item => (
            console.log(item.cliNom)
        ))

        console.log(clienteNom)

        console.log("el cliente nom es " + clienteNom);

        setClienteId(999) //Prueba para usar una bandera
    }
}

const handleInputChange = (evento) => {
    //Asigno los valores del formulario a las variables locales de la página
    //evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
    setDatos({
        ...datos,
        [evento.target.name] : evento.target.value
    })
    console.log(datos)
};

//Algo que se dispara cuando se pierde el foco
const handleBlur = (e) =>{
    //Disparo el evento de cambio de formulario pues ahi estan los controles
    handleInputChange(e); 
    //setErrores(validarFormulario(datos));
}


const enviarDatos= (evento) => {
    //preventDefault es para que el formulario no actue de forma automática, sino que yo pueda 
    //controlarlo mediante JS
    evento.preventDefault();
    var problemas='';
    var todoOK;
    todoOK=true;
    if(!datos.CliNom.trim())
    {
        setError('Nombre es obligatorio');
        todoOK=false;
    }

    if(todoOK)
    {
        if(!datos.CliTel.trim()&&!datos.CliEmail.trim())
        {
            setError('Teléfono o Email son obligatorios');
            todoOK=false;
        }

        if(datos.CliEmail.trim())
        {
            var email=datos.CliEmail;
            if(!validator.isEmail(email)){
                setError('El formato del email ingresado no es correcto');
                todoOK=false;    
            }
        }
    }

    if(todoOK)
    {
        dispatch(enviarClienteAccion(datos));

        //asumo que todo salió ok... me voy al listado de nuevo
        navigate("/clientes");  
    }
}


    return (

        <div className='container mt-5'>
            <Fragment>
            <Menu></Menu>
            <hr/>
            <h3>Nuevo cliente</h3>
            <form name='Frmcliente' className='row' onSubmit={enviarDatos}>

                    {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }
                <div className="col-md-5">
                    <label>
                        Nombre Fantasía:<input 
                        name="CliNom"
                        type="text"
                        placeholder="Ingrese Nombre"
                        className="form-control"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        value={datos.cliNom}
                        />
                    </label>
                </div>
                <div className="col-md-5">
                    Dirección:<input
                    name="CliDir"
                    type="text" 
                    placeholder="Ingrese Dirección"
                    className="form-control"
                    onChange={handleInputChange}          
                    onBlur={handleBlur}     
                    value={datos.CliDir} 
                    />
                </div>
                <div className="col-md-5">
                    Teléfono:<input
                    name="CliTel"
                    type="text" 
                    placeholder="Ingrese Teléfono"
                    className="form-control"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    />
                </div>
                <div className="col-md-5">
                    EMail:<input
                    name="CliEmail"
                    type="text" 
                    placeholder="Ingrese E-Mail"
                    className="form-control"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    />
                </div>


                <div className="col-md-5">
                    Nombre de Contacto:<input
                    name="CliCntNom"
                    type="text" 
                    placeholder="Nombre Contacto"
                    className="form-control"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    />
                </div>

                <div className="col-md-5">
                    Teléfono de Contacto:<input
                    name="CliCntTel"
                    type="text" 
                    placeholder="Teléfono contacto"
                    className="form-control"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    />
                </div>
                <div className="col-md-5">
                    Razón Social:<input
                    name="CliRazSoc"
                    type="text" 
                    placeholder="Razón Social"
                    className="form-control"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    />
                </div>

                <div className="col-md-5">
                    R.U.T.:<input
                    name="CliRut"
                    type="text" 
                    placeholder="R.U.T."
                    className="form-control"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    />
                </div>

                <div className="col-md-5">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Guardar</button>
                </div>

            </form>
            </Fragment>
        </div>
    )
}
export default Cliente