import React, {Fragment, useState} from 'react'
import { enviarUsuarioAccion } from '../redux/usuariosDucks'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react'
import validator from 'validator'
import { useParams } from 'react-router-dom';
import Menu from '../components/menu.jsx';
import sha256 from 'crypto-js/sha256';

export const NuevoUsuario = () => {

const navigate = useNavigate();
const dispatch = useDispatch()

//const { id } = useParams();

const[datos, setDatos]=useState(
{
    UsrId:0,
    UsrUsuario:'',
    UsrNombreCompleto: '',
    UsrCorreoUsuario: '',
    UsRolUsurio: '',
    UsrEstado: 'A',
    UsrClave:'',
    UsrClave1:'',
    UsrClave2:''
});



useEffect (function () {
  
    setDatos({
        ...datos,
        UsrUsuario:'Mauricio'               
    })
    console.log('On loading');
}, []);


//Variable que acumulará los errores encontrados de validación
const[error,setError]=React.useState(null);

const handleInputChange = (evento) => {
    //Asigno los valores del formulario a las variables locales de la página
    //evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
    setDatos({
        ...datos,
        [evento.target.name] : evento.target.value
    })
    console.log('pasaron cosas');
    console.log(datos);
};

const enviarDatos= (evento) => {
    //preventDefault es para que el formulario no actue de forma automática, sino que yo pueda 
    //controlarlo mediante JS
    evento.preventDefault();
    setError('');
    var problemas='';
    var todoOK;
    todoOK=true;

    if(!datos.UsrUsuario.trim())
    {
        setError('Nombre de usuario es obligatorio');
        todoOK=false;
    }

    if(!datos.UsrNombreCompleto.trim())
    {
        setError('Nombre completo es obligatorio');
        todoOK=false;
    }

    console.log(datos)
    if(datos.UsrClave1.trim() && datos.UsrClave2.trim())
    {
        
        if(datos.UsrClave1 != datos.UsrClave2)
            {
                setError('La clave y su repeticion no coinciden');
                todoOK=false;
            }
    }
    else
    {
        setError('Debe ingresar La clave y su repeticion (y deben ser iguales)');
        todoOK=false;
    }


    if(todoOK)
    {
        console.log(datos);

        dispatch(enviarUsuarioAccion(datos));

        //asumo que todo salió ok... me voy al listado de nuevo
        navigate("/listadoUsuarios");  
    }
}

function clearForm() {
    console.log('limpiando formulario');
}

    return (
        
        <div className='container mt-5'>
            <Fragment>
            <Menu></Menu>
            <hr/>
            <h3>Ingreso de un nuevo Usuario</h3>
            <form name='Frmusuario' className='row' onSubmit={enviarDatos} autocomplete="new-password">

                    {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }


                <div className="col-md-5">
                    <label>
                        Nombre de Usuario:<input 
                        name="UsrUsuario"
                        type="text"
                        placeholder="Nombre de usuario"
                        className="form-control"
                        onChange={handleInputChange}
                        autocomplete="new-password"
                        />
                    </label>
                </div>

                <div className="col-md-5">
                    <label>
                        Nombre de Usuario:<input 
                        name="UsrNombreCompleto"
                        type="text"
                        placeholder="Nombre completo"
                        className="form-control"
                        onChange={handleInputChange}
                        />
                    </label>
                </div>

                <div className="col-md-5">
                    <label>
                        Correo electrónico:<input 
                        name="UsrCorreoUsuario"
                        type="text"
                        placeholder="Correo electrónico"
                        className="form-control"
                        onChange={handleInputChange}
                        />
                    </label>
                </div>

                <div className="col-md-5">
                    <label>
                        Ingrese clave:<input 
                        name="UsrClave1"
                        type="password"
                        placeholder="Ingrese clave"
                        className="form-control"
                        onChange={handleInputChange}
                        />
                    </label>
                </div>

                <div className="col-md-5">
                    <label>
                        Repita clave:<input 
                        name="UsrClave2"
                        type="password"
                        placeholder="Repita clave"
                        className="form-control"
                        onChange={handleInputChange}
                        />
                    </label>
                </div>


                <div className="col-md-5">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Guardar</button>
                </div>

            </form>
            </Fragment>
        </div>
    )
}
export default NuevoUsuario