import React, {Fragment, useState, useEffect} from 'react'
import {Spinner} from "reactstrap";
import Cargando from './loading'
import { useParams } from 'react-router-dom';
import validator from 'validator'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { modificarChipAccion,obtenerUnChipAccion } from '../redux/chipDucks'
import Menu from './menu.jsx';

const EditarChip =() => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const[error,setError]=React.useState(null);
    const [loading, setLoading] = useState(false);
    const [refrescarObjeto, setrefrescarObjeto] = useState(false);
    const [listaChip,setChips]= useState([]);
    const { id } = useParams();
    const chips = useSelector(store => store.chips.unChip)
   

    const[datos, setDatos]=useState(chips[0]);

    React.useEffect (() => {
        
        const obtenerDatos = async () => {            
            try
            {
                setLoading(true);
                const res = await dispatch(obtenerUnChipAccion(id));               
                setLoading(false);
               
            }
            catch(error){
                console.log(error)
            }
        }
        obtenerDatos()      

    }, []);

    useEffect(() => {
        const elChip = chips.filter(chip => chip.chipId = id);
        if (elChip.length > 0)
            return setDatos(elChip[0]);
    }, [chips])

    const handleInputChange = (evento) => {
        //Asigno los valores del formulario a las variables locales de la página
        //evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
        var campo=evento.target.name;
        var valor;
        
        if (campo!="chipActivo"){
            valor=evento.target.value;
        }else {
            if (datos.chipActivo=="SI"){
                valor=true
            }else{
                valor=false
            }
        }
        
        setDatos({
            ...datos,
            [evento.target.name] : valor
        })
    };

    const enviarDatos= (evento) => {
        evento.preventDefault();
            var problemas='';
            var todoOK;
            todoOK=true;

        if(!datos.chipNom.trim())
        {
            setError('Nombre chip es obligatorio');
            todoOK=false;
        }
        if(!datos.chipFabricante.trim())
        {
            setError('Nombre fabricante es obligatorio');
            todoOK=false;
        }
       
        
        if(todoOK)
        {
            console.log(datos)
            dispatch(modificarChipAccion(id, datos));
            //asumo que todo salió ok... me voy al listado de nuevo
            navigate("/chips");  
        }
        
    }

    return (
            
        <Fragment>
             <Menu></Menu>
             <hr/>
             <h3>Editar un chip</h3>
             {loading ? (
                 <Fragment>
                    <Cargando/>
                </Fragment>
                ) :
                
                (
                    datos?
                            <form name='Frmcliente' id='Frmcliente' className='row' onSubmit={enviarDatos} >
                            {
                                error && (
                                    <div className='alert alert-danger'>
                                        {error}
                                    </div>
                                )
                            }
                        <div className="col-md-5">
                            <label>
                                Nombre chip:<input 
                                name="chipNom"
                                type="text"
                                placeholder="Ingrese nombre"
                                className="form-control"
                                onChange={handleInputChange}
                                value={datos?.chipNom || ''}
                                />
                            </label>
                        </div>
                        <p></p>
                        <div className="col-md-5">
                            Fabricante:<input
                            name="chipFabricante"
                            type="text" 
                            placeholder="Ingrese fabricante"
                            className="form-control"
                            onChange={handleInputChange}   
                            value={datos?.chipFabricante || ''}       
                            />
                        </div>
                        {/* <div className="col-md-5">
                            Activo:<input
                            name="chipActivo"
                            type="text" 
                            placeholder="Seleccione valor"
                            className="form-control"
                            onChange={handleInputChange}
                            
                            value={datos.chipActivo ==true ? "SI":'NO'}
                            />
                        </div> */}
                        <p></p>
                        <div className="col-md-5">
                                <button 
                                className='btn btn-primary'
                                type="submit"
                                >Guardar</button>
                            </div> 
                            <p></p>                
                    </form>
                    :
                    <></>
                    )                    
            }
        </Fragment>
    )
    
}

export default EditarChip
