import React, {Fragment, useState} from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { obtenerClientesAccion,borrarClienteAccion } from '../redux/clienteDucks'
import Menu from '../components/menu.jsx';

const Clientes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
   
    const clientes = useSelector(store => store.clientes.array)

    useEffect (async function () {
        console.log("refrescando pantalla");
        const timer = setTimeout(() => {
        ActualizarClientes();
        }, 1000);
        return () => clearTimeout(timer);

        console.log("termine de refrescar");
        //console.log(resultado);
       }, []);


    async function ActualizarClientes () {
        console.log("trayendo clientes");
        await dispatch(obtenerClientesAccion(filtronombre))
        console.log("ya traje los clientes");
        var retorno;
        dispatch(obtenerClientesAccion(filtronombre,retorno))
        console.log(retorno);
       }

    const NuevoCliente = () => {
        navigate("/fichacliente/0");            
    }

    const eliminarElemento = async (id) => {
        dispatch(borrarClienteAccion(id))      
        await ActualizarClientes();
    }
    
    const editarElemento = (id) => {
        navigate("/editarCliente/" + id);            
    }

    const[filtronombre, setFiltroNombre]=useState('')
    
    const handleInputChange = (evento) => {
        var todoOK;
        todoOK=true;
        var nombre = evento.target.value;
        setFiltroNombre(nombre)
    }

const obtenerClientes= (evento) => {
    evento.preventDefault()
    console.log("Tratando de obtener");
    dispatch(obtenerClientesAccion(filtronombre))
}

   return (
        <div>
            <Menu></Menu>
            <hr/>
            <h3>Lista de Clientes</h3>
            <form className='row' onSubmit={obtenerClientes}>
                <div className="col-md-4">
                    <input 
                        name="CliNom"
                        type="text"
                        placeholder="Ingrese Nombre"
                        className="form-control"
                        onChange={handleInputChange}
                        />
                </div>                
                <div className="col-md-5">                            
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Buscar</button>
                    <button onClick={NuevoCliente}
                        className='btn btn-primary'
                    >Nuevo Cliente</button>
                </div>
            </form>                

<table width="100%" border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Nombre</th>
      <th scope="col">Dirección</th>
      <th scope="col">Teléfono</th>
      <th scope="col" align="center">Acciones</th>
    </tr>
  </thead>
  <tbody>
{
    clientes.map(item => (
<tr>
    <td>
        {item.cliNom}
    </td>
    <td>
        {item.cliDir}
    </td>
    <td>
        {item.cliTel}
    </td>
    <td align="center">
    <button type="button" class="btn btn-default" onClick={() => editarElemento(item.cliId)}>
    <img src='./img/pencil.svg'   />
    </button>
    <button type="button" class="btn btn-default" onClick={() => eliminarElemento(item.cliId)}>
    <img src='./img/trash.svg'   />
    </button>
    </td>
</tr>

    ))
    }


</tbody>
</table>

        </div>
    )
}

export default Clientes