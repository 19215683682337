import React, {Fragment, useState} from 'react'
import { enviarMarcadorAccion,obtenerUnMarcadorAccion } from '../redux/marcadoresDucks'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import validator from 'validator'
import { useParams } from 'react-router-dom';
import Menu from '../components/menu.jsx';

export const Marcador = () => {

const navigate = useNavigate();
const dispatch = useDispatch()

const { id } = useParams();

const[datos, setDatos]=useState(
{
    //MarId:0,
    MarNom:'',
    MarUsrCreacion: "Admin",
    MarActivo:true
});

//Variable que acumulará los errores encontrados de validación
const[error,setError]=React.useState(null);
const[marcadorId,setMarcadorId]=React.useState(0);

const[marcadorNom,setMarcadorNom]=React.useState('');

const [obtenerMarcador,setObtenerMarcador]= useState([]);

const marcadores = useSelector(store => store.marcadores.array)

const handleInputChange = (evento) => {
    //Asigno los valores del formulario a las variables locales de la página
    //evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
    setDatos({
        ...datos,
        [evento.target.name] : evento.target.value
    })
    console.log(datos)
};

//Algo que se dispara cuando se pierde el foco
const handleBlur = (e) =>{
    //Disparo el evento de cambio de formulario pues ahi estan los controles
    handleInputChange(e); 
    //setErrores(validarFormulario(datos));
}


const enviarDatos= (evento) => {
    //preventDefault es para que el formulario no actue de forma automática, sino que yo pueda 
    //controlarlo mediante JS
    evento.preventDefault();
    var problemas='';
    var todoOK;
    todoOK=true;
    if(!datos.MarNom.trim())
    {
        setError('Nombre es obligatorio');
        todoOK=false;
    }
    if(todoOK)
    {
        dispatch(enviarMarcadorAccion(datos));

        //asumo que todo salió ok... me voy al listado de nuevo
        navigate("/marcadores");  
    }
}


    return (

        <div className='container mt-5'>
            <Fragment>
            <Menu></Menu>
            <hr/>
            <h3>Nuevo marcador</h3>
            <form name='Frmcliente' className='row' onSubmit={enviarDatos}>

                    {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }
                <div className="col-md-5">
                    <label>
                        Nombre Marcador:<input 
                        name="MarNom"
                        type="text"
                        placeholder="Ingrese Nombre"
                        className="form-control"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        value={datos.marNom}
                        />
                    </label>
                </div>
                <p></p>
                <div className="col-md-5">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Guardar</button>
                </div>

            </form>
            </Fragment>
        </div>
    )
}
export default Marcador