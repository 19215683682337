import React, {Fragment, useState, useEffect} from 'react'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {obtenerIdMuestraBiologicaAccion,editarMuestraBiologicaAccion,obtenerMuestrasBiologicasAccion } from '../redux/muestrasBiologicasDucks'
import {obtenerChipAccion, obtenerUnChipAccion} from '../redux/chipDucks'
import {obtenerEspecieAccion, obtenerUnaEspecieAccion} from '../redux/especieDucks'
import DatePicker from "react-datepicker";
import Cargando from './loading'
import Menu from '../components/menu.jsx';
import entorno from '../config.js'


const EditarMuestraBiologica =() => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const base_url=entorno("BASE_URL");
    const [listaClientes,setClientes]= useState([]);
    const [listaEspecies,setEspecies]= useState([]);
    const [listaChips,setChips]= useState([]);
    const [listaTpoMuestras,setTpoMuestras]= useState([]);
    const[error,setError]=React.useState(null);
    const [loading, setLoading] = useState(false);
    const [datos, setDatos] = useState(null);
    const { id } = useParams();
    const muestras = useSelector(store => store.muestras.unaMuestra)
    const chips = useSelector(store => store.chips.unChip)
    const [cliNombre, setCliNombre] = useState(null);
    const [espNombre, setEspNombre] = useState(null);
    const [chipNombre, setChipNombre] = useState(null);
    const [tpoMuestraNombre, setTpoMuestraNombre] = useState(null);
    const [chipId, setChipId] = useState(null);
    const [especieId, setEspecieId] = useState(null);
    const [tpoId, setTpoId] = useState(null);
    const [fechaIngreso, setFechaIngreso] = useState(null);
    const [fechaExtracion, setFechaExtracion] = useState(null);
    const [fechaNacimiento, setFechaNacimiento] = useState(null);
    const [sexo,setSexo]= useState();


  

    useEffect (() => {
        
        const obtenerDatos = async () => {            
            try
            {
                setLoading(true);
                const res = await dispatch(obtenerIdMuestraBiologicaAccion(id));                 
                 
                setLoading(true);  


            setLoading(false);
            }
            catch(error){
                console.log(error)
            }

            
        }
        obtenerDatos()  
      
      

        axios.get(base_url + 'genexa/clientes')
        .then((response) => setClientes(response.data.value.data))
        .then ((error)=> console.log(error));

        axios.get(base_url + 'genexa/especies')
        .then((response) => setEspecies(response.data))
        .then ((error)=> console.log(error));

        axios.get(base_url + 'genexa/chips')
        .then((response) => setChips(response.data))
        .then ((error)=> console.log(error));

        
        axios.get(base_url + 'genexa/tipodemuestras')
        .then((res) => setTpoMuestras(res.data))
        .then ((error)=> console.log(error));


        console.log("Muestra")
        console.log(datos)
    }, []);


    useEffect(() => {
        
        const muestraBiologica = muestras.filter(muestra => muestra.mstId = id);
        if (muestraBiologica.length > 0){
            return setDatos(muestraBiologica[0]);
             
        }      
        
    }, [muestras])
    

    const seleccionarCliente = (e) =>{
        console.log(e.target.value)
        var valor=Number(e.target.value);
    
        setDatos({
            ...datos,
            cliId : valor
           
            
        })
        
    };

    const seleccionarTpoMuestra = (e) =>{
        console.log(e.target.value)
        var valor=Number(e.target.value);
    
        setDatos({
            ...datos,
            tpoMstId : valor
           
            
        })
        
    };

    const SeleccionEstado= [
        {
          label: "Pendiente",
          value: "P",
        },
        {
          label: "Finalizado",
          value: "F",
        },
        {
            label: "Entregado",
            value: "E",
          },
        {
            label: "Cancelado",
            value: "C",
        },
    ]

    const SeleccionSexo = [
        {
          label: "Hembra",
          value: "H",
        },
        {
          label: "Macho",
          value: "M",
        },
        {
          label: "Sin Dato",
          value: "S/D",
        },
      ]

    const seleccionarEspecie = (e) =>{
        console.log(e.target.value)
        var valor=Number(e.target.value);
    
        setDatos({
            ...datos,
            espId : valor         
        })       
    };

    const seleccionarChip = (e) =>{
        console.log(e.target.value)
        var valor=Number(e.target.value);
    
        setDatos({
            ...datos,
            chipId : valor          
        })
    };

    const seleccionarSexo = (e) =>{
        setDatos({
            ...datos,
            mstSexo : e.target.value
                 
        })
        
    };

    const handleChangeFechaIngreso = (dateI) => {
    
        setFechaIngreso(dateI);
        setDatos({
            ...datos,
            mstFchIng : dateI.toLocaleDateString("en-CA")
            
        })
        
      }; 
    
      const handleChangeFechaExtracion = (dateE) => {
    
        setFechaExtracion(dateE);
        setDatos({
            ...datos,
            mstFchExt : dateE.toLocaleDateString("en-CA")
            
        })
      }; 
    
      const handleChangeFechaNacimiento = (dateN) => {
    
        setFechaNacimiento(dateN);
        setDatos({
            ...datos,
            mstFchNac :  dateN.toLocaleDateString("en-CA")
        })
      };

    const handleBlur = (e) =>{
        //Disparo el evento de cambio de formulario pues ahi estan los controles
        handleInputChange(e); 
        //setErrores(validarFormulario(datos));
    } 
    
    

    const handleInputChange = (evento) => {
        //Asigno los valores del formulario a las variables locales de la página
        //evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
        var campo=evento.target.name;
        var valor;

        if(campo==="chipId"||campo==="tpoMstId" ||campo==="espId"|| campo==="mstCliente")
        valor=Number(evento.target.value);
        else
            valor=evento.target.value;
        setDatos({
            ...datos,
            [evento.target.name] : evento.target.value
        })

    };

    async function ActualizarMuestraBilogica () {
        var Fecha1= '1900-01-01';
        var Fecha2='1900-01-01';
        var Fecha3='1900-01-01';
        var Fecha4='1900-01-01';
            
        setTimeout(function(){
            dispatch(obtenerMuestrasBiologicasAccion(datos,Fecha1,Fecha2,Fecha3,Fecha4))
            }, 500);        
    }


    const enviarDatos= (evento) => {
        //preventDefault es para que el formulario no actue de forma automática, sino que yo pueda 
        //controlarlo mediante JS
        evento.preventDefault();
        var problemas='';
        var todoOK;
        todoOK=true;
        if(!datos.espId===0)
        {
            setError('El campo especie es obligatorio');
            todoOK=false;
        }
    
    
        if(todoOK)
        {
           dispatch(editarMuestraBiologicaAccion(id, datos));
           ActualizarMuestraBilogica();
            //asumo que todo salió ok... me voy al listado de nuevo
           navigate("/muestras");  
        }
    }


        return (
            
            <Fragment>
                
            <Menu></Menu>
            <hr/>
            <h3>Editar una muestra biológica</h3>
                 {loading ? (
                    <Fragment>
                        <Cargando/>
                    </Fragment>
                 
                    ) :

                    (                        
                        datos?
                                <form name='Frmcliente' id='Frmcliente' className='row' onSubmit={enviarDatos} >
                                {
                                    error && (
                                        <div className='alert alert-danger'>
                                            {error}
                                        </div>
                                    )
                                }
                        
                            <div className="col-md-5">
                            Especie: 
                            <select className="form-control col-md-5"  onChange={seleccionarEspecie}>
                            {/* {<option selected >{datos.espNom}</option>} */}
                            <option value="" selected="selected" hidden="hidden">{datos.espNom}</option>
                              {
                                listaEspecies.map ((especies) => 
                                
                                
                                    <option key={especies.espId} value={especies.espId}>{especies.espNombre}</option>
                                    
                                )                    
                              }
                          </select>    
                            </div>

                            <div className="col-md-5">
                            Chip: 
                            <select className="form-control col-md-5" onChange={seleccionarChip}>
                            {/* {<option selected>{datos.chipNombre}</option>} */}
                            <option value="" selected="selected" hidden="hidden">{datos.chipNombre}</option>
                         
                              {
                                listaChips.map ((chips) => 
                                
                                    <option key={chips.chipId} value={chips.chipId}>{chips.chipNom}</option>
                                    
                                )                    
                              }
                          </select>    
                            </div>

                            <div className="col-md-5">
                                Código interno:<input
                                name="mstCodInt"
                                type="text" 
                                placeholder="Codigo interno"
                                className="form-control"
                                onChange={handleInputChange}
                                value={datos?.mstCodInt || ''}
                                disabled={true}
                                />
                            </div>

                            <div className="col-md-5">
                                 Tipo Muestra: 
                            <select className="form-control col-md-5"  onChange={seleccionarTpoMuestra}>
                            <option value="" selected="selected" hidden="hidden">{datos.tpoMstNom}</option>
                              {
                                listaTpoMuestras.map ((muestras) => 
                                
                                    <option key={muestras.tpoMstId} value={muestras.tpoMstId}>{muestras.tpoMstNom}</option>
                                    
                                )                    
                              }
                          </select> 
                            </div>

                            <div className="col-md-5">
                                Fecha de ingreso:
                                <DatePicker  className="form-control col-md-5"
                                type="text"
                                dateFormat="yyyy-MM-dd" 
                                placeholder="Fecha de Ingreso"                        
                                value={datos?.mstFchIng}
                                //selected={datos?.mstFchIng}
                                onChange={handleChangeFechaIngreso}
                                onBlur={handleBlur}
                                >
                                </DatePicker >
                    
                            </div>

                            <div className="col-md-5">
                                Fecha de extracción:
                            <DatePicker className="form-control col-md-5"
                                type="text"
                                dateFormat="yyyy-MM-dd" 
                                placeholder="Fecha de Extracción"
                                value={datos?.mstFchExt=='1900-01-01T00:00:00' && 'Sin datos'|| datos?.mstFchExt}
                                onChange={handleChangeFechaExtracion}
                                onBlur={handleBlur}
                        >
                            </DatePicker>
                            </div>

                            <div className="col-md-5">
                                Fecha de nacimiento:
                            <DatePicker className="form-control col-md-5"
                                type="text"
                                dateFormat="yyyy-MM-dd" 
                                placeholder="Fecha de Nacimiento"
                                value={datos?.mstFchNac=='1900-01-01T00:00:00' && 'Sin datos'|| datos?.mstFchNac}
                                onChange={handleChangeFechaNacimiento}
                                onBlur={handleBlur}
                            >
                            </DatePicker>

                            </div>

                            {/* <div className="col-md-5">
                                Sexo:<input
                                name="mstSexo"
                                type="text" 
                                placeholder="Ingrese Sexo"
                                className="form-control"
                                onChange={handleInputChange}   
                                value={datos.mstSexo}       
                                />
                            </div> */}
                                <div className="col-md-5">
                                Sexo:  
                                <select className="form-control col-md-5" onChange={seleccionarSexo}>   
                                <option value="" selected="selected" hidden="hidden">{datos.mstSexo}</option>       
                                {SeleccionSexo.map((option) => (
                                <option value={option.value}>{option.label}</option>
                                
                        ))}
                              
                   </select>       
                  </div>
                            <div className="col-md-5">
                            Cliente: 
                            <select className="form-control col-md-5" onChange={seleccionarCliente}>
                            {/* <option value="0">{datos.cliNombre}</option> */}
                            <option value="" selected="selected" hidden="hidden">{datos.cliNombre}</option>
                         
                              {
                                listaClientes.map ((cliente) => 
                                
                                    <option key={cliente.cliId} value={cliente.cliId}>{cliente.cliNom}</option>
                                    
                                )                    
                              }
                          </select>    
                            </div>

                            <div className="col-md-5">
                                Código de Origen:<input
                                name="mstCodOrigen"
                                type="text" 
                                placeholder="Codigo Origen"
                                className="form-control"
                                onChange={handleInputChange}
                                value={datos?.mstCodOrigen || ''}
                                />
                            </div>

                            <div className="col-md-5">
                                Tiene ADN:<input
                                name="mstTieneADN"
                                type="text" 
                                placeholder="Tiene ADN"
                                className="form-control"
                                onChange={handleInputChange}
                                value = {datos.mstTieneADN}
                                disabled={true}
                                />
                            </div>

                            {/* <div className="col-md-5">
                                Estado:<input
                                name="mstEstado"
                                type="text" 
                                placeholder="Estado"
                                className="form-control"
                                onChange={handleInputChange}
                                value={datos.mstEstado}
                                disabled={true}
                                />
                            </div> */}

                            <p></p>
                            <div className="col-md-5">
                                <button 
                                className='btn btn-primary'
                                type="submit"
                                >Guardar</button>
                            </div>
                            
                        </form>
                        
                        :
                        <></>
                        )   
             }
             </Fragment>
        )
}
export default EditarMuestraBiologica