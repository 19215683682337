import axios from 'axios'
import "react-datepicker/dist/react-datepicker.css";
import React, {Fragment, useState} from 'react'
import { Component } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { obtenerClientesAccion } from '../redux/clienteDucks'
import { enviarMuestraBiologicaAccion,obtenerMuestrasBiologicasAccion } from '../redux/muestrasBiologicasDucks'
import Select from 'react-select'
import entorno from '../config.js'
import validator from 'validator'
import { useEffect } from 'react'
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Menu from '../components/menu.jsx';
import { format } from 'date-fns';


const base_url=entorno("BASE_URL");


const MuestraBiologica = () => {

  const [fechaIngreso, setFechaIngreso] = useState(null);
  const [fechaExtracion, setFechaExtracion] = useState(null);
  const [fechaExtracionVacio, setfechaExtracionVacio] = useState(null);
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const { id } = useParams();
  const[error,setError]=React.useState(null);
  const [listaClientes,setClientes]= useState([]);
  const [obtenerCliente,setObtenerCliente]= useState([]);
  const [listaChips,setChips]= useState([]);
  const [listaEspecies,setEspecies]= useState([]);
  const [obtenerChips,setObtenerChips]= useState([]);
  const [listaTpoMuestras,setTpoMuestras]= useState([]);
  const [obtenerTpoMuestra,setObtenerTpoMuestra]= useState([]);
  const [sexo,setSexo]= useState();
  const [proxnro,setproxnro]= useState();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const[datos, setDatos]=useState(
  {
    EspId:0,
    ChipId: 0,
    MstCodInt: '',
    TpoMstId: 0,
    MstFchIng: format(new Date(), 'yyyy-MM-dd'),
    MstFchExt:"1900-01-01",
    MstFchNac: format(new Date(), 'yyyy-MM-dd'),
    MstSexo:'',
    CliId:0,
    MstCodOrigen:'',
    MstTieneADN:'No',
    MstEstado:'Pendiente',
    MstActivo:true,
    MstUsrCreacion: 'admin'
  });


  const SeleccionSexo = [
    {
      label: "Hembra",
      value: "H",
    },
    {
      label: "Macho",
      value: "M",
    },
    {
      label: "Sin Dato",
      value: "S/D",
    },
  ]


useEffect (function () {
  
    axios.get(base_url + 'genexa/clientes')
    .then((response) => setClientes(response.data.value.data))
    .then ((error)=> console.log(error));

    axios.get(base_url +'genexa/chips')
    .then((res) => setChips(res.data))
    .then ((error)=> console.log(error));

    axios.get(base_url + 'genexa/tipodemuestras')
    .then((res) => setTpoMuestras(res.data))
    .then ((error)=> console.log(error));

    axios.get(base_url + 'genexa/especies')
    .then((res) => setEspecies(res.data))
    .then ((error)=> console.log(error));

    axios.get(base_url + 'genexa/muestrasbiologicas/proximonro')
    .then((res) => setDatos({...datos, MstCodInt : 'GNX' + res.data }))
    .then ((error)=> console.log(error));

    

    //Cargo los valores por defecto
    var hoy=format(new Date(), 'yyyy-MM-dd');
    setFechaIngreso(new Date());
    // setFechaExtracion(new Date());
    // setFechaNacimiento(new Date());
}, []);


 

const oddlChange = (e) =>{
    var valor=Number(e.target.value);

    setDatos({
        ...datos,
        CliId : valor
       
        
    })
    
};

const seleccionChip = (e) =>{
    var valor=Number(e.target.value);

    setDatos({
        ...datos,
        ChipId : valor
       
        
    })
    
};

const seleccionEspecie = (e) =>{
    var valor=Number(e.target.value);

    setDatos({
        ...datos,
        EspId : valor
       
        
    })
    
};

const seleccionTpoMuestra = (e) =>{
    var valor=Number(e.target.value);

    setDatos({
        ...datos,
        TpoMstId : valor
       
        
    })
    
};

const cambioSexo = (e) =>{
    setDatos({
        ...datos,
        MstSexo : e.target.value
       
        
    })
    
};


    //Variable que acumulará los errores encontrados de validación
  const[errores,setErrores]=React.useState({});
  
  const handleInputChange = (evento) => {
    //Asigno los valores del formulario a las variables locales de la página
    //evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T

    var campo=evento.target.name;
    var valor;

    if(campo==="ChipId"||campo==="EspId"||campo==="TpoMstId")
        valor=Number(evento.target.value);
    else
        valor=evento.target.value;
    setDatos({
        ...datos,
        [evento.target.name] : valor
    })
};

const handleChangeFechaIngreso = (dateI) => {
    
    setFechaIngreso(dateI);
    setDatos({
        ...datos,
        MstFchIng : dateI.toLocaleDateString("en-CA")
        
    })
    
  }; 

  const handleChangeFechaExtracion = (dateE) => {
console.log("entro fecha extracion")
    if(dateE == null )
    {
        console.log("entre a null en fecha nac");
        var fecha = new Date(1900, 0, 1, 0, 0, 0, 0);
        setFechaExtracion(null);
        setDatos({
            ...datos,
            MstFchExt :  fecha.toLocaleDateString("en-CA")
        })
    }
    else
    {
        setFechaExtracion(dateE);
        setDatos({
            ...datos,
            MstFchExt :  dateE.toLocaleDateString("en-CA")
        })    
    }
  }; 


 const handleChangeFechaNacimiento = (dateN) => {
    if(dateN == null )
    {
        console.log("entre a null en fecha nac");
        var fecha = new Date(1900, 0, 1, 0, 0, 0, 0);
        setFechaNacimiento(null);
        setDatos({
            ...datos,
            MstFchNac :  fecha.toLocaleDateString("en-CA")
        })
    }
    else
    {
        setFechaNacimiento(dateN);
        setDatos({
            ...datos,
            MstFchNac :  dateN.toLocaleDateString("en-CA")
        })    
    }

  };
 

//Algo que se dispara cuando se pierde el foco
const handleBlur = (e) =>{
    //Disparo el evento de cambio de formulario pues ahi estan los controles
    handleInputChange(e); 
    //setErrores(validarFormulario(datos));
} 

async function ActualizarMuestraBilogica () {
    var Fecha1= '1900-01-01';
    var Fecha2='1900-01-01';
    var Fecha3='1900-01-01';
    var Fecha4='1900-01-01';
       
   setTimeout(function(){
       dispatch(obtenerMuestrasBiologicasAccion(datos,Fecha1,Fecha2,Fecha3,Fecha4))
       }, 500);        
}
  
const enviarDatos= (evento) => {
    //preventDefault es para que el formulario no actue de forma automática, sino que yo pueda 
    //controlarlo mediante JS
    evento.preventDefault();
    //console.log('Enviando datos');   
    var problemas='';
    var todoOK;
    todoOK=true;

    if(datos.EspId===0)
    {
        setError('Campo especie es obligatorio');
        todoOK=false;
    }

    if(!datos.MstCodInt.trim())
    {
        setError('Campo código interno es obligatorio');
        todoOK=false;
    }

    if(datos.ChipId===0)
    {
        setError('Campo chip es obligatorio');
        todoOK=false;
    }

    if(datos.CliId===0)
    {
        setError('Campo cliente es obligatorio');
        todoOK=false;
    }

    if(datos.TpoMstId===0)
    {
        setError ('Campo tipo de muestra es obligatorio');
        todoOK=false;
    }

    if(datos.MstFchNac != '1900-01-01' && datos.MstFchNac > datos.MstFchExt && datos.MstFchExt != '1900-01-01')
    {
        setError ('Campo fecha de nacimiento debe ser menor o igual a fecha extraccion');
        todoOK=false;
    }

    if(datos.MstFchNac != '1900-01-01' &&  datos.MstFchNac > datos.MstFchIng )
    {
        setError ('Campo fecha de nacimiento debe ser menor o igual a fecha ingreso');
        todoOK=false;
    }

    if(datos.MstFchExt != '1900-01-01' && datos.MstFchExt > datos.MstFchIng )
    {
        setError ('Campo fecha de extración debe ser menor o igual a fecha de ingreso');
        todoOK=false;
    }


    console.log(datos);

    if(todoOK)
    {
        
        dispatch(enviarMuestraBiologicaAccion(datos));
        ActualizarMuestraBilogica();
        navigate("/muestras");  
    }
    else
    {
        console.log(problemas);
    }
}
  
      return (
          <div>
                            <Menu></Menu>
              <hr/>
              <Fragment>
              <h3>Nueva muestra biológica</h3>
              <form className='row' onSubmit={enviarDatos}>
                     {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                     }
                  <div className="col-md-5">
                      Especie:
                      <select className="form-control col-md-5" onChange={seleccionEspecie}>
                          <option value="0" >Seleccione especie</option>
                              {
                                listaEspecies.map ((especie) => 
                                
                                    <option key={especie.espId} value={especie.espId}>{especie.espNombre}</option>
                                    
                                )                    
                              }
                          </select>  
                    
                  </div>

                  <div className="col-md-5">
                      Chip:
                      <select className="form-control col-md-5" onChange={seleccionChip}>
                          <option value="0" >Seleccione chip</option>
                              {
                                listaChips.map ((chip) => 
                                
                                    <option key={chip.chipId} value={chip.chipId}>{chip.chipNom}</option>
                                    
                                )                    
                              }
                          </select>  
                      
                  </div>

                  <div className="col-md-5">
                      Codigo Interno:<input
                      name="MstCodInt"
                      value={datos.MstCodInt}
                      type="text" 
                      placeholder="Ingrese Codigo Interno"
                      className="form-control"
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      disabled="true"
                      />
                  </div>

                  <div className="col-md-5">
                      Tipo de Muestra:
                       <select className="form-control col-md-5" onChange={seleccionTpoMuestra}>
                          <option value="0" >Seleccione tipo de muestra</option>
                              {
                                listaTpoMuestras.map ((muestra) => 
                                
                                    <option key={muestra.tpoMstId} value={muestra.tpoMstId}>{muestra.tpoMstNom}</option>
                                    
                                )                    
                              }
                          </select>    
                  </div>

                  <div className="col-md-5">
                     Fecha de Ingreso:
                    <DatePicker  className="form-control col-md-5"
                        type="text"
                        dateFormat="yyyy-MM-dd" 
                        placeholder="Fecha de Ingreso"  
                        selected={fechaIngreso}
                        onChange={handleChangeFechaIngreso}
                        onBlur={handleBlur}
                         >
                     </DatePicker>
                  </div>
  
                  <div className="col-md-5">
                    Fecha de Extracción:
                    <DatePicker className="form-control col-md-5"
                       type="text"
                        dateFormat="yyyy-MM-dd" 
                        placeholder="Fecha de Extracción"
                        isClearable
                        selected={fechaExtracion}
                        onChange={handleChangeFechaExtracion}
                        onBlur={handleBlur}
                        placeholderText="Seleccione fecha"    
                                           
                        >
                     </DatePicker>
                      
                  </div>
                  <div className="col-md-5">
                    Fecha de Nacimiento:
                    <DatePicker className="form-control col-md-5"
                        type="text"
                        dateFormat="yyyy-MM-dd" 
                        placeholder="Fecha de Nacimiento"
                        isClearable
                        onChange={handleChangeFechaNacimiento}
                        selected={fechaNacimiento}
                        onBlur={handleBlur}
                        placeholderText="Seleccione fecha"
                        >
                     </DatePicker>
                  </div>
  
                  <div className="col-md-5">
                  Sexo:  
                  <h1>{sexo}</h1>
                  <select className="form-control col-md-5" onChange={cambioSexo}>   
                  <option value="0" >Seleccione sexo</option>       
                    {SeleccionSexo.map((option) => (
                     <option value={option.value}>{option.label}</option>
                        ))}
                              
                   </select>       
                  </div>
                  
                  <div className="col-md-5">
                          Cliente: 
                          <select className="form-control col-md-5" onChange={oddlChange}>
                          <option value="0" >Seleccione cliente</option>
                              {
                                listaClientes.map ((cliente) => 
                                
                                    <option key={cliente.cliId} value={cliente.cliId}>{cliente.cliNom}</option>
                                    
                                )                    
                              }
                          </select>                    
                        </div>

                  <div className="col-md-5">
                      Codigo de Origen:<input
                      name="MstCodOrigen"
                      type="text" 
                      placeholder="Codigo de Origen"
                      className="form-control"
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      />
                  </div>

                  <p></p>
                  <div className="col-md-5">
                      <button 
                      className='btn btn-primary'
                      type="submit"
                      >Guardar</button>
                  </div>
                  <p></p>
              </form>
              
  
              </Fragment>
  
          </div>
      )
  }

export default MuestraBiologica