import React, {Fragment, useState} from 'react'
import {Spinner} from "reactstrap";
import Cargando from './loading'
import { useParams } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Menu from '../components/menu.jsx';
import {CSVLink} from 'react-csv';
import { reporteEstudioFiliacion } from '../redux/estudioFiliacionDucks'

const EstudioFiliacionReporte =() => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const[error,setError]=React.useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const resultado = useSelector(store => store.estudiosFiliaciones.unReporte)
    const[datos, setDatos]=useState(resultado);
    
    React.useEffect (() => {
        
        const obtenerDatos = async () => {            
            try
            {
                setLoading(true);
                const res = await dispatch(reporteEstudioFiliacion(id));             
                console.log(datos);
                setLoading(false);
            }
            catch(error){
                console.log(error)
            }
        }
        obtenerDatos()      

    }, []);

        return (
            
            <Fragment>                
            <Menu></Menu>
            <hr/>
                 {loading ? (
                     <Fragment>
                        <Cargando/>
                    </Fragment>
                    ) :
                    
                    (
                    datos?
                    <div >
                    <p align='center'>
                    <CSVLink data={resultado} filename={"tablaResultadoEstudio.csv"}>
                            Exportar resultado a CSV <img src='../img/csv.svg'   />
                    </CSVLink>
                    </p>
                    <table width="100%" border="1" class="table table-striped">

                    <tbody>
                    <tr>
                        <td><img src='../img/logo.jpg'   /></td>
                        <td><h1>RESULTADO DE FILIACION</h1></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    {
                        resultado.map(item => (
                    <tr>
                        <td>{item.columna1}</td>
                        <td>{item.columna2}</td>
                        <td>{item.columna3}</td>
                        <td>{item.columna4}</td>
                        <td>
                            {item.columna5=='Exclusión' ? <b>Exclusión</b> : item.columna5}                            
                        </td>
                    </tr>
                    ))
                        }
                    </tbody>
                    </table>


                    </div>
                    :
                        <></>

                    )
                }
            </Fragment>
        )
}
export default EstudioFiliacionReporte