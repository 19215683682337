import React, {Fragment, useState, useEffect} from 'react'
import {Spinner} from "reactstrap";
import Cargando from './loading'
import { useParams } from 'react-router-dom';
import validator from 'validator'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { modificarTipoMuestraAccion,obtenerUnTipoMuestraAccion } from '../redux/tipoMuestraDucks'
import Menu from '../components/menu.jsx';

const EditarTipoMuestra =() => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const[error,setError]=React.useState(null);
    const [loading, setLoading] = useState(false);
    const [refrescarObjeto, setrefrescarObjeto] = useState(false);
    const [listaTiposMuestras,setTiposMuestras]= useState([]);
    const { id } = useParams();
    const [EsActivo, setEsActivo] = useState(false);
    const muestras = useSelector(store => store.tiposMuestas.unTipoMuestra)
    

    const[datos, setDatos]=useState(muestras[0]);

    
    useEffect (() => {
        
        const obtenerDatos = async () => {            
            try
            {
                setLoading(true);
                const res = await dispatch(obtenerUnTipoMuestraAccion(id));               
                setLoading(false);
            }
            catch(error){
                console.log(error)
            }
        }
        obtenerDatos()      

    }, []);

    useEffect(() => {
        const elTipoMuestra = muestras.filter(muestra => muestra.mstId = id);
        if (elTipoMuestra.length > 0)
            return setDatos(elTipoMuestra[0]);
    }, [muestras])

    const handleInputChange = (evento) => {
        //Asigno los valores del formulario a las variables locales de la página
        //evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
        setDatos({
            ...datos,
            [evento.target.name] : evento.target.value
        })
    };

    
    const handleEstadoactivo = (evento) => {
        if(EsActivo==true)
           setEsActivo(false)
        else
        setEsActivo(true)

        setDatos({
            ...datos,
            tpoMstActivo : EsActivo
        })

    };



    const enviarDatos= (evento) => {
        evento.preventDefault();
            var problemas='';
            var todoOK;
            todoOK=true;
            // if(!datos.tpoMstNom.trim())
            // {
            //     setError('Nombre tipo de muestra es obligatorio');
            //     todoOK=false;
            // }
        
            // if(!datos.TpoMstDetExt.trim())
            // {
               
            //     setError('Detalle de Extracción es obligatorio');
            //     todoOK=false;    
                
            //  }
        
            if(todoOK)
            {
                console.log(datos)
                dispatch(modificarTipoMuestraAccion(id, datos));
        
                //asumo que todo salió ok... me voy al listado de nuevo
                navigate("/tiposdemuestra");  
            }
        
    }
    

        return (
            
            <Fragment>                
            <Menu></Menu>
            <hr/>
            <h3>Editar un tipo de muestra</h3>
                 {loading ? (
                     <Fragment>
                        <Cargando/>
                    </Fragment>
                    ) :
                    
                    (
                        datos?
                                <form name='Frmcliente' id='Frmcliente' className='row' onSubmit={enviarDatos} >
                                {
                                    error && (
                                        <div className='alert alert-danger'>
                                            {error}
                                        </div>
                                    )
                                }
                            <div className="col-md-5">
                                <label>
                                    Tipo de Muestra:<input 
                                    name="tpoMstNom"
                                    type="text"
                                    placeholder="Nombre de tipo de muestra"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={datos.tpoMstNom}
                                    />
                                </label>
                            </div>
                            <p></p>
                            <div className="col-md-5">
                                Detalle de Extracción:<input
                                name="tpoMstDetExt"
                                type="text" 
                                placeholder="Detalle de extracción"
                                className="form-control"
                                onChange={handleInputChange}   
                                value={datos.tpoMstDetExt}       
                                />
                            </div>
                            <p></p>

                            <p></p>
                            <div className="col-md-5">
                                <button 
                                className='btn btn-primary'
                                type="submit"
                                >Guardar</button>
                            </div>   
                            <p></p>                         
                        </form>
                        :
                        <></>
                        )                    
                }
            </Fragment>
        )
}
export default EditarTipoMuestra