import axios from 'axios'
import entorno from '../config.js'
import alerta from '../components/alertas';
const base_url=entorno("BASE_URL");

// constantes
// constantes
const dataInicial = {
    array : [],
    unaFiliacion : [],
    unReporte : [],
    offset: 0
}

// types
const OBTENER_FILIACIONES_EXITO = 'OBTENER_FILIACIONES_EXITO'
const ENVIAR_FILIACIONES_EXITO = 'ENVIAR_FILIACIONES_EXITO'
const OBTENER_UNA_FILIACION_EXITO = 'OBTENER_UNA_FILIACION_EXITO'
const MODIFICAR_FILIACION_EXITO = 'MODIFICAR_FILIACION_EXITO'
const CALCULAR_FILIACION_EXITO = 'CALCULAR_FILIACION_EXITO'
const REPORTE_FILIACION_EXITO = 'REPORTE_FILIACION_EXITO'

// reducer
export default function estudiosFiliacionesReducer(state = dataInicial, action){
    switch(action.type){
        case OBTENER_FILIACIONES_EXITO:
            return {...state, array: action.payload}
        case OBTENER_UNA_FILIACION_EXITO:
                return {...state, unaFiliacion: action.payload}       
        case REPORTE_FILIACION_EXITO:
                return {...state, unReporte: action.payload}           
        default:
            return state
    }
}

    
    export const enviarEstudioFiliacionAccion = (datos) => async (dispatch, getState) => {
        console.log ("Entro en funcion")
        //console.log (datos)

         const params = {
             EstId:datos.EstId,
             EstCod: datos.EstCod,
             EstFchIng: datos.EstFchIng,
             EstFchProm: datos.EstFchProm,
             EstFchRes: datos.EstFchRes,
             EstPanId:datos.EstPanId,
             EstMstMadre:datos.EstMstMadre,
             EstMstPadre:datos.EstMstPadre,
             EstMstHijo:datos.EstMstHijo,
             EstResCalc:datos.EstResCalc,
             EstEstado:datos.EstEstado,
             EstMotCancelacion:datos.EstMotCancelacion,
             EstUsrCreacion: 'vivi'
         }
         console.log(params);
        try {
            
            const res = await axios.post(base_url + 'genexa/estudiosdepaternidad',params)
            dispatch({
                type: ENVIAR_FILIACIONES_EXITO,
                payload: res.data
            })
            alerta("Mant. de Estudios", res.data.value.tipo, res.data.value.message);
        } catch (error) {
            console.log(error)
            alerta("Mant. de Estudios", "ERROR", "Problemas de comunicación, reintente por favor");
        }
}


export const obtenerEstudioFilacionAccion = (filtros) => async (dispatch, getState) => {
    console.log("Llamada al Duck - OK")

    const params = {
       EstCod: filtros.EstCod,
       Desde: filtros.Desde,
       Hasta: filtros.Hasta,
       EstResCalc: filtros.EstResCalc,
       EstEstado: filtros.EstEstado
   }


   try {
            var direc=base_url + 'genexa/estudiosdepaternidad';
            const res = await axios.get(direc,{params});
            dispatch({
                type: OBTENER_FILIACIONES_EXITO,
                payload: res.data
            })
            console.log(res.data);
        } catch (error) {
            console.log(error)
        }
    }


    export const obtenerEstudioFiliacionPorIdAccion = (id) => async (dispatch, getState) => {

        try {
            var filtro='';
            if(id.trim())
            {
                filtro='/'+id;
            }
            var direc=base_url + 'genexa/estudiosdepaternidad/consultarestudioporcodigo' + filtro;
            
            const res = await axios.get(direc);
            dispatch({
                type: OBTENER_UNA_FILIACION_EXITO,
                payload: res.data
            })        
        } catch (error) {
            console.log(error)
        }
    }



export const obtenerIdEstudioFiliacionAccion = (id) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(id.trim())
        {
            filtro='/'+id;
        }
        var direc=base_url + 'genexa/estudiosdepaternidad' + filtro;
        
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_UNA_FILIACION_EXITO,
            payload: res.data
        })        
    } catch (error) {
        console.log(error)
    }
}

export const editarEstudioFiliacionAccion = (id,datos) => async (dispatch, getState) => {

    const estudioFiliacionModificado = {
            estResCalc: datos.estResCalc,
            EstEstado: datos.estEstado,
            EstObservaciones: datos.estObservaciones
    }
    
    try {
        console.log(id)
        console.log (estudioFiliacionModificado)        
        const res = await axios.put(base_url + 'genexa/estudiosdepaternidad/'+id,estudioFiliacionModificado)
        dispatch({
            type: MODIFICAR_FILIACION_EXITO,
            payload: res.data
        })
        alerta("Mant. de Estudios", res.data.value.tipo, res.data.value.message);
    } catch (error) {
        console.log("Error de envio de datos");
        alerta("Mant. de Estudios", "ERROR", "Problemas de comunicación, reintente por favor");
    }
}

export const calcularEstudioFiliacion = (id) => async (dispatch, getState) => {

    try {
        console.log(id)
        const res = await axios.get(base_url + 'genexa/estudiosdepaternidad/calcularestudio/'+id)
        dispatch({
            type: CALCULAR_FILIACION_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const reporteEstudioFiliacion = (id) => async (dispatch, getState) => {

    try {
        console.log(id)
        const res = await axios.get(base_url + 'genexa/estudiosdepaternidad/consultarresultadoestudio/'+id)
        dispatch({
            type: REPORTE_FILIACION_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}