import axios from 'axios'
import entorno from '../config.js'

import alerta from '../components/alertas';
const base_url=entorno("BASE_URL");

// constantes
const dataInicial = {
    array : [],
    uncliente : [],
    offset: 0,
    message:""
}

// types
const OBTENER_CLIENTES_EXITO = 'OBTENER_CLIENTES_EXITO'
const ENVIAR_CLIENTE_EXITO = 'ENVIAR_CLIENTE_EXITO'
const OBTENER_UN_CLIENTE_EXITO = 'OBTENER_UN_CLIENTES_EXITO'
const MODIFICAR_CLIENTE_EXITO = 'MODIFICAR_CLIENTE_EXITO'


// reducer
export default function clienteReducer(state = dataInicial, action){
    switch(action.type){
         case OBTENER_CLIENTES_EXITO:
            return {...state, array: action.payload}
        case OBTENER_UN_CLIENTE_EXITO:
            return {...state, uncliente: action.payload}
        default:
            return state
    }
}

// acciones
export const obtenerClientesAccion = (nombre) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(nombre.trim())
        {
            filtro='/'+nombre;
        }
        
        var direc=base_url + 'genexa/clientes' + filtro;
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_CLIENTES_EXITO,
            payload: res.data.value.data,
        })
    } catch (error) {
        console.log(error)
    }
}

export const obtenerUnClienteAccion = (id) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(id.trim())
        {
            filtro='/'+id;
        }
        var direc=base_url + 'genexa/clientes' + filtro;
        console.log(direc);
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_UN_CLIENTE_EXITO,
            payload: res.data.value.data,          
        })                
    } catch (error) {
        console.log(error)
    }
}

export const enviarClienteAccion = (datos) => async (dispatch, getState) => {

    try {
        const res = await axios.post(base_url + 'genexa/clientes',datos)
        dispatch({
            type: ENVIAR_CLIENTE_EXITO,
            payload: res.data
        })
        alerta("Mant. de Clientes", res.data.value.tipo, res.data.value.message);
    } catch (error) {
        console.log(error)
        alerta("Mant. de Clientes", "ERROR", "Problemas de comunicación, reintente por favor");
    }
}

export const borrarClienteAccion = (id) => async (dispatch, getState) => {

    try {
        const res = await axios.delete(base_url + 'genexa/clientes/'+id)
        dispatch({
            type: ENVIAR_CLIENTE_EXITO,
            payload: res.data
        })
        alerta("Mant. de Clientes", res.data.value.tipo, res.data.value.message);
    } catch (error) {
        console.log(error)
        alerta("Mant. de Clientes", "ERROR", "Problemas de comunicación, reintente por favor");
    }
}


export const modificarClienteAccion = (id,datos) => async (dispatch, getState) => {

    //Preparo el objeto que voy a enviar.-
    //No incluyo el ID del elemento
    // OJO con los tipos de datos, si hay que convertir, hay que hacerlo antes.-
    const clienteModificado = {
        CliNom:datos.cliNom,
        CliDir: datos.cliDir,
        CliTel: datos.cliTel,
        CliEmail: datos.cliEmail,
        CliCntNom: datos.cliCntNom,
        CliCntTel:datos.cliCntTel,
        CliRazSoc:datos.cliRazSoc,
        CliRut:datos.cliRut
    };

    try {
        const res = await axios.put(base_url + 'genexa/clientes/'+id,clienteModificado)
        dispatch({
            type: MODIFICAR_CLIENTE_EXITO,
            payload: res.data
        })
        alerta("Mant. de Clientes", res.data.value.tipo, res.data.value.message);
    } catch (error) {
        console.log(error)
        alerta("Mant. de Clientes", "ERROR", "Problemas de comunicación, reintente por favor");
    }
}
