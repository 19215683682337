import React, {Fragment, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { enviarCambioClaveAccion } from '../redux/usuariosDucks'
import { useNavigate } from 'react-router-dom';
import validator from 'validator'
import { useParams } from 'react-router-dom';
import Menu from '../components/menu.jsx';


export const CambioClave = () => {

const navigate = useNavigate();
const dispatch = useDispatch()

const { id } = useParams();

const[datos, setDatos]=useState(
{
    UsrUsuario:'',
    ClaveActual:'',
    ClaveNueva1: '',
    ClaveNueva2: ''
});

//Variable que acumulará los errores encontrados de validación
const[error,setError]=React.useState(null);
const usuarios = useSelector(store => store.usuarios.array)

const handleInputChange = (evento) => {
    //Asigno los valores del formulario a las variables locales de la página
    //evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
    setDatos({
        ...datos,
        [evento.target.name] : evento.target.value
    })
};



const enviarDatos= (evento) => {
    //preventDefault es para que el formulario no actue de forma automática, sino que yo pueda 
    //controlarlo mediante JS
    evento.preventDefault();
    var problemas='';
    var todoOK;
    todoOK=true;
    setError('');

    if(!datos.ClaveActual.trim())
    {
        setError('Debe ingresar la clave actual');
        todoOK=false;
    }

    if(datos.ClaveNueva1.trim()&&datos.ClaveNueva2.trim())
    {
        if(datos.ClaveNueva1.trim()!=datos.ClaveNueva2.trim())
        {
            setError('Clave nueva y su repetición DEBEN ser identicas');
            todoOK=false;
        }
    }
    else
    {
        setError('Clave nueva y su repetición son obligatorios');
        todoOK=false;
    }

    
    console.log(datos);

     if(todoOK)
     {
        
            

        console.log("todo ok - se enviará la solicitud")
        
         dispatch(enviarCambioClaveAccion(datos));
         
         navigate('/home');  
     }


}
    return (

        <div className='container mt-4'>
            <Fragment>
            <Menu></Menu>
            <hr/>
             <h3>Cambio de clave</h3>
            <form name='FrmcambioDeClave' className='row' onSubmit={enviarDatos}>

                    {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }

                <div className="col-md-3">
                    <label>
                        Clave actual:<input 
                        name="ClaveActual"
                        type="password"
                        placeholder="Ingrese clave actual"
                        className="form-control"
                        onChange={handleInputChange}
                        />
                    </label>
                </div>

                <div className="col-md-3">
                    <label>
                        Clave nueva:<input 
                        name="ClaveNueva1"
                        type="password"
                        placeholder="Ingrese clave nueva"
                        className="form-control"
                        onChange={handleInputChange}
                        />
                    </label>
                </div>
                <div className="col-md-3">
                    Repita clave nueva:<input
                    name="ClaveNueva2"
                    type="password" 
                    placeholder="Repita Clave Nueva"
                    className="form-control"
                    onChange={handleInputChange}          
                    />
                </div>
<p>
                <div className="col-md-3">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Enviar solicitud de cambio</button>
                </div>
</p>
            </form>
            </Fragment>
        </div>
    )
}
export default CambioClave