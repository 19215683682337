import React, {Fragment, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { obtenerUsuariosAccion,borrarUsuarioAccion } from '../redux/usuariosDucks'
import Menu from '../components/menu.jsx';

const Usuarios = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
   
    const listaUsuarios = useSelector(store => store.usuarios.array)
    
    const NuevoElemento = () => {
        navigate("/NuevoUsuario");            
    }

    const eliminarElemento = (id) => {
        dispatch(borrarUsuarioAccion(id))   
        alert("El usuario ha sido eliminado con éxito")
        navigate("/listadoUsuarios")      
        dispatch(obtenerUsuariosAccion(filtronombre))
    }
    
    const editarElemento = (id) => {
        navigate("/editarUsuario/" + id);            
    }

    const[filtronombre, setFiltroNombre]=useState('')
    
    const handleInputChange = (evento) => {
        var todoOK;
        todoOK=true;
        var nombre = evento.target.value;
        setFiltroNombre(nombre)
    }

    const obtenerUsuarios= (evento) => {
        evento.preventDefault()
        dispatch(obtenerUsuariosAccion(filtronombre))
    }

   return (
        <div>
            <Menu></Menu>
            <hr/>
            <h3>Lista de Usuarios</h3>
            <form className='row' onSubmit={obtenerUsuarios}>
                <div className="col-md-4">
                    Nombre:
                    <input 
                        name="UsuNom"
                        type="text"
                        placeholder="Ingrese Nombre"
                        className="form-control"
                        onChange={handleInputChange}
                        />                    
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Buscar</button>
                    <button onClick={NuevoElemento}
                        className='btn btn-primary'
                    >Nuevo Usuario</button>
                </div>
            </form>                

<table width="100%" border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Usuario</th>
      <th scope="col">Nombre Completo</th>
      <th scope="col">EMail</th>
      <th scope="col" align="center">Acciones</th>
    </tr>
  </thead>
  <tbody>
{
    listaUsuarios.map(item => (
<tr>
    <td>
        {item.usrUsuario}
    </td>
    <td>
        {item.usrNombreCompleto}
    </td>
    <td>
        {item.usrCorreoUsuario}
    </td>
    <td align="center">
    <button type="button" class="btn btn-default" onClick={() => editarElemento(item.usrId)}>
    <img src='./img/pencil.svg'   />
    </button>
    <button type="button" class="btn btn-default" onClick={() => eliminarElemento(item.usrId)}>
    <img src='./img/trash.svg'   />
    </button>
    </td>
</tr>

    ))
    }


</tbody>
</table>

        </div>
    )
}

export default Usuarios