import axios from 'axios'
import entorno from '../config.js'
const base_url=entorno("BASE_URL");

// constantes

const dataInicial = {
    array : [],
    unaMuestra : [],
    offset: 0
}

// types
const OBTENER_MUESTRAS_EXITO = 'OBTENER_MUESTRAS_EXITO'
const ENVIAR_MUESTRAS_EXITO = 'ENVIAR_MUESTRAS_EXITO'
const OBTENER_UNA_MUESTRA_EXITO = 'OBTENER_UNA_MUESTRA_EXITO'
const MODIFICAR_MUESTRA_EXITO = 'MODIFICAR_MUESTRA_EXITO'

// reducer
export default function muestrasBiologicasReducer(state = dataInicial, action){
    switch(action.type){
        case OBTENER_MUESTRAS_EXITO:
            return {...state, array: action.payload}
        case OBTENER_UNA_MUESTRA_EXITO:
                return {...state, unaMuestra: action.payload}
        default:
            return state
    }
}

 

export const obtenerMuestrasBiologicasAccion =  (datos,fIngresoInicio,fIngresoFin,fExtraccionInicio,fExtraccionFin) => async (dispatch, getState) => {

    const params = {
        EspId:datos.EspId,
        ChipId: 0,
        MstCodInt:datos.MstCodInt,
        TpoMstId: 0,
        MstFchIng: '1900-01-01',
        MstFchExt:'1900-01-01',
        MstFchNac:'1900-01-01',
        MstSexo:'',
        CliId: datos.CliId,
        MstCodOrigen:datos.MstCodOrigen,
        MstTieneADN:datos.MstTieneADN,
        MstEstado:'',
        MstActivo:null,
        MstUsrCreacion: 'Admin',

        MstfechaIngresoInicio:fIngresoInicio,
        MstfechaIngresoFin:fIngresoFin,
        MstfechaExtraccionInicio:fExtraccionInicio,
        MstfechaExtraccionFin:fExtraccionFin

    }
    try {
        const res = await axios.get(base_url + 'genexa/muestrasbiologicas',{params});        
        dispatch({            
           type: OBTENER_MUESTRAS_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}
    
    export const enviarMuestraBiologicaAccion = (datos) => async (dispatch, getState) => {
        console.log("Los datos son")
        console.log(datos)
        try {
            
            const res = await axios.post(base_url + 'genexa/muestrasbiologicas',datos)
            dispatch({
                type: ENVIAR_MUESTRAS_EXITO,
                payload: res.data
            })

        } catch (error) {
            console.log("Error de envio de datos");
            console.log(error)
        }
}

export const obtenerUnaMuestraBiologicaAccion = (datos,fIngresoInicio,fIngresoFin,fExtraccionInicio,fExtraccionFin) => async (dispatch, getState) => {

    const params = {
       
        EspId: datos.espId,
        ChipId: datos.chipId,
        ChipNombre: datos.chipNombre,
        CliId: datos.cliId,
        CliNombre: datos.cliNombre,
        EspNom: datos.espNom,
        MstActivo: datos.mstActivo,
        MstCliente: datos.mstCliente,
        MstCodInt: datos.mstCodInt,
        MstCodOrigen: datos.mstCodOrigen,
        MstEspecie: datos.mstEspecie,
        MstEstado: datos.mstEstado,
        MstFchActualizacion: datos.mstFchActualizacion,
        MstFchCreacion: datos.mstFchCreacion,
        MstFchExt: datos.mstFchExt,
        MstFchIng: datos.mstFchIng,
        MstFchNac: datos.mstFchNac,
        MstId: datos.mstId,        
        MstSexo: datos.mstSexo,
        MstTieneADN: datos.mstTieneADN,
        MstUsrActualizacion: datos.mstUsrActualizacion,
        MstUsrCreacion: datos.mstUsrCreacion,
        TpoMstId: datos.tpoMstId,
        TpoMstNom: datos.tpoMstNom,

    }
    try {
        const res = await axios.get(base_url + 'genexa/muestrasbiologicas',{params});        
        dispatch({            
           type: OBTENER_MUESTRAS_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const obtenerIdMuestraBiologicaAccion = (id) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(id.trim())
        {
            filtro='/'+id;
        }
        var direc=base_url + 'genexa/muestrasbiologicas' + filtro;
        
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_UNA_MUESTRA_EXITO,
            payload: res.data
        })        
    } catch (error) {
        console.log(error)
    }
}

export const editarMuestraBiologicaAccion = (id,datos) => async (dispatch, getState) => {

    console.log("Entro editar")
    console.log(datos)
    const muestraBiologicaModificada = {

        EspId:datos.espId,
        ChipId: datos.chipId,
        MstCodInt:datos.mstCodInt,
        TpoMstId: datos.tpoMstId,
        MstFchIng: datos.mstFchIng,
        MstFchExt:datos.mstFchExt,
        MstFchNac:datos.mstFchNac,
        MstSexo:datos.mstSexo,
        CliId: datos.cliId,
        MstCodOrigen:datos.mstCodOrigen,
        MstTieneADN:datos.mstTieneADN,
        MstEstado:datos.mstEstado,
        MstActivo:datos.mstActivo,
        MstUsrCreacion: 'Admin',


    }
    
    try {
        console.log("muestra editada")
        console.log (muestraBiologicaModificada)        
        const res = await axios.put(base_url + 'genexa/muestrasbiologicas/'+id,muestraBiologicaModificada)
        dispatch({
            type: MODIFICAR_MUESTRA_EXITO,
            payload: res.data
        })

    } catch (error) {
        console.log("Error de envio de datos");
        console.log(error)
    }
}

export const borrarMuesrtaBiologica = (id) => async (dispatch, getState) => {

    try {
        const res = await axios.delete(base_url + 'genexa/muestrasbiologicas/'+id)
        dispatch({
            type: ENVIAR_MUESTRAS_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}