import React, {Fragment, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { obtenerArchivosAccion,borrarArchivosAccion,defsabdAccion,generarPerfilesAccion } from '../redux/archivosDucks'
import Menu from '../components/menu.jsx';


const Archivos = () =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const archivos = useSelector(store => store.archivos.array)
    //console.log(archivos)

    const[Estado,setEstado]=React.useState('P');

    function convertDateFormat(string) {
        var info=string.substring(0,10);
        info = info.split('-');
        return info[2] + '/' + info[1] + '/' + info[0];
    }

    const eliminarElemento = (id) => {
        dispatch(borrarArchivosAccion(id))     
        navigate("/archivos");     
    }
   
    const procesar_FSaBD = (id) => {
        dispatch(defsabdAccion(id))     
        navigate("/");            
        refreshPage();     
    }

    const procesar_GenerarPerfiles = (id) => {
        dispatch(generarPerfilesAccion(id))
        //history.push("/")
        navigate("/");            
        refreshPage();     
    }

    function refreshPage() {
        window.location.reload(false);
      }

    const cambioEstado = (e) =>{
        setEstado(e.target.value);        
    };

    const agregarArchivo = (id) => {
        navigate("/subirArchivoSNP/" + id);            
    }

    const obtenerArchivos= (evento) => {
        evento.preventDefault()
        dispatch(obtenerArchivosAccion(Estado))
    }

    return (
        <div>            
            <Menu></Menu>
            <hr/>
            <h3>Lista de Archivos</h3>
            <form className='row' onSubmit={obtenerArchivos}>
                <div className="col-md-5">
                    <select  className="form-control col-md-6" onChange={cambioEstado}>  
                        <option value='P'>Pendiente</option>                              
                        <option value='A'>En análisis (Archivo -> BD)</option> 
                        <option value='I'>Importado (para procesar)</option>                                                      
                        <option value='F'>Ya Procesado (perfiles creados)</option>                              
                        <option value='C'>Descartado</option>                              
                    </select>  
                </div>
                <div className="col-md-5">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Buscar</button>
                    <button onClick={agregarArchivo}
                        className='btn btn-primary'
                    >Subir nuevo Archivo</button>
                </div>
                <div>
                    <center>Acciones (dependen del estado) : 
                    <img src='./img/trash.svg'   /> : Eliminar el Archivo -  
                    <img src='./img/upload.svg'   /> : Leer TXT y subir a Base de datos - 
                    <img src='./img/gear.svg'   /> : Generar perfiles genéticos y asociar a muestras 
                    </center>
                </div>
            </form>                

<table width="100%" border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Nombre</th>
      <th scope="col">Fecha</th>
      <th scope="col">Estado</th>
    </tr>
  </thead>
  <tbody>
{
    archivos.map(item => (
<tr>
    <td>
        {item.archNom}
    </td>
    <td>
        {convertDateFormat(item.archFchIng)}
    </td>
    <td>
        {item.archEstado}
    </td>
    <td align="center"></td>

    {item.archEstado=='Pendiente' &&

        <button type="button" class="btn btn-default" onClick={() => procesar_FSaBD(item.archId)}> <img src='./img/upload.svg'   /></button> 

    }
    {item.archEstado=='Importado' &&

        <button type="button" class="btn btn-default" onClick={() => procesar_GenerarPerfiles(item.archId)}> <img src='./img/gear.svg'   /></button> 
    }

    {item.archEstado!='Descartado' &&
        <button type="button" class="btn btn-default" onClick={() => eliminarElemento(item.archId)}>    <img src='./img/trash.svg'   />
        </button>  
    }  
</tr>
))
    }
  </tbody>
</table>

</div>
    )
}

export default Archivos