import axios from 'axios'
import entorno from '../config.js'
import alerta from '../components/alertas';
const base_url=entorno("BASE_URL");

// constantes
const dataInicial = {
    array : [],
    unMarcador : [],
    offset: 0,
    message:""
}

// types
const OBTENER_MARCADORES_EXITO = 'OBTENER_MARCADORES_EXITO';
const ENVIAR_MARCADOR_EXITO = 'ENVIAR_MARCADOR_EXITO'
const OBTENER_UN_MARCADOR_EXITO = 'OBTENER_UN_MARCADOR_EXITO'
const MODIFICAR_MARCADOR_EXITO = 'MODIFICAR_MARCADOR_EXITO'

// reducer
export default function marcadoresReducer(state = dataInicial, action){
    
    switch(action.type){
        case OBTENER_MARCADORES_EXITO:
            return {...state, array: action.payload}
        case OBTENER_UN_MARCADOR_EXITO:
            return {...state, unMarcador: action.payload}
        default:
            return state
    }
}

// acciones
// export const obtenerMarcadoresAccion = () => async (dispatch, getState) => {
//     try {
//         var direc=base_url + 'genexa/marcadores';
//         const res = await axios.get(direc);
//         dispatch({
//             type: OBTENER_MARCADORES_EXITO,
//             payload: res.data.value.data
//         })
//     } catch (error) {
//         console.log(error)
//     }
// }

export const obtenerMarcadoresAccion = (nombre) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(nombre.trim())
        {
            filtro='/'+nombre;
        }
        
        var direc=base_url + 'genexa/marcadores' + filtro;
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_MARCADORES_EXITO,
            payload: res.data.value.data,
        })
    } catch (error) {
        console.log(error)
    }
}

export const obtenerUnMarcadorAccion = (id) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(id.trim())
        {
            filtro='/'+id;
        }
        var direc=base_url + 'genexa/marcadores' + filtro;
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_UN_MARCADOR_EXITO,
            payload: res.data.value.data,          
        })                
    } catch (error) {
        console.log(error)
    }
}

export const enviarMarcadorAccion = (datos) => async (dispatch, getState) => {

    try {
        const res = await axios.post(base_url + 'genexa/marcadores',datos)
        dispatch({
            type: ENVIAR_MARCADOR_EXITO,
            payload: res.data
        })
        alerta("Mant. de Marcadores", res.data.value.tipo, res.data.value.message);
    } catch (error) {
        console.log(error)
        alerta("Mant. de Marcadores", "ERROR", "Problemas de comunicación, reintente por favor");
    }
}


export const borrarMarcadorAccion = (id) => async (dispatch, getState) => {

    try {
        const res = await axios.delete(base_url + 'genexa/marcadores/'+id)
        dispatch({
            type: ENVIAR_MARCADOR_EXITO,
            payload: res.data
        })
        alerta("Mant. de Marcadores", res.data.value.tipo, res.data.value.message);
    } catch (error) {
        console.log(error)
        alerta("Mant. de Marcadores", "ERROR", "Problemas de comunicación, reintente por favor");
    }
}


export const modificarMarcadorAccion = (id,datos) => async (dispatch, getState) => {

    const marcadorModificado = {
        MarNom:datos.marNom,
    };

    try {
        const res = await axios.put(base_url + 'genexa/marcadores/'+id,marcadorModificado)
        dispatch({
            type: MODIFICAR_MARCADOR_EXITO,
            payload: res.data
        })
        alerta("Mant. de Marcadores", res.data.value.tipo, res.data.value.message);
    } catch (error) {
        console.log(error)
        alerta("Mant. de Marcadores", "ERROR", "Problemas de comunicación, reintente por favor");
    }
}
