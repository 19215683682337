import axios from 'axios'
import entorno from '../config.js'
const base_url=entorno("BASE_URL");

// constantes
const dataInicial = {
    array : [],
    offset: 0
}

// types
const OBTENER_PANELES_EXITO = 'OBTENER_PANELES_EXITO';
const ELIMINAR_PANEL_EXITO = 'ELIMINAR_PANEL_EXITO';
const ENVIAR_PANEL_EXITO = 'ENVIAR_PANEL_EXITO';

// reducer
export default function panelReducer(state = dataInicial, action){
    switch(action.type){
        case OBTENER_PANELES_EXITO:
            return {...state, array: action.payload}
        default:
            return state
    }
}

// acciones
export const obtenerPanelesAccion = (nombre) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(nombre.trim())
        {
            filtro='/'+nombre;
        }
        var direc=base_url + 'genexa/paneles' + filtro;
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_PANELES_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const borrarPanelAccion = (id) => async (dispatch, getState) => {

    try {
        const res = await axios.delete(base_url + 'genexa/paneles/'+id)
        dispatch({
            type: ELIMINAR_PANEL_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const enviarPanelAccion = (datos) => async (dispatch, getState) => {

    console.log(datos);
    try {
        const res = await axios.post(base_url + 'genexa/paneles',datos)
        dispatch({
            type: ENVIAR_PANEL_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}