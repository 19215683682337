import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Dropdown,DropdownItem,DropdownMenu, DropdownToggle,DropdownButton, DropDownItem} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import manualPdf from "../docs/ManualDeUsuario3.pdf";

const cookies = new Cookies();

const Menu = () => {

const navigate = useNavigate();
const [dropdown1, setDropDown1]=useState(false);
const abrirCerrarDropdown1=()=>{
    setDropDown1(!dropdown1);
}
const [dropdown2, setDropDown2]=useState(false);
const abrirCerrarDropdown2=()=>{
    setDropDown2(!dropdown2);
}

const [dropdown3, setDropDown3]=useState(false);
const abrirCerrarDropdown3=()=>{
    setDropDown3(!dropdown3);
}

const [dropdown4, setDropDown4]=useState(false);
const abrirCerrarDropdown4=()=>{
    setDropDown4(!dropdown4);
}

const [dropdown5, setDropDown5]=useState(false);
const abrirCerrarDropdown5=()=>{
    setDropDown5(!dropdown5);
}

const [dropdown6, setDropDown6]=useState(false);
const abrirCerrarDropdown6=()=>{
    setDropDown6(!dropdown6);
}

const [dropdown7, setDropDown7]=useState(false);
const abrirCerrarDropdown7=()=>{
    setDropDown6(!dropdown7);
}


//--------- Mant. de datos

const accionPaneles=()=>{
    navigate("/paneles");
}

const accionClientes=()=>{
    navigate("/clientes");
}

const accionMuestras=()=>{
    navigate("/muestras");
}

const accionEspecies=()=>{
    navigate("/especies");
}

const accionTiposDeMuestra=()=>{
    navigate("/tiposdemuestra");
}

const accionChip=()=>{
    navigate("/chips");
}

const accionArchivosDePerfiles=()=>{
    navigate("/archivos");
}

const accionMarcador=()=>{
    navigate("/marcadores");
}

//--- Estudios de filiación

const accionEstudiosFilaciones=()=>{
    navigate("/listado");
}

//--- Búsqueda de base de datos
const accionBuscarPerfil=()=>{
    navigate("/BuscarPerfilBD");
}

//--- Búsqueda de mutaciones
const accionVerificarMuestraBiologica=()=>{
    navigate("/listadoMutaciones");
}

//--- Menú de seguridad
const accionTareas=()=>{
    navigate("/listadoTareas");
}
const accionRoles=()=>{
    navigate("/listadoRoles");
}
const accionUsuarios=()=>{
    navigate("/listadoUsuarios");
}

const accionCambioClave=()=>{
    navigate("/cambioClave");
}


const accionCerrarSesion=()=>{
    cookies.remove('usrId');
    cookies.remove('usrNombreCompleto');
    cookies.remove('usrRolUsuario');
    navigate("/");
}



const accionAbrirManual=()=>{
    window.open(manualPdf, '_blank');
}


React.useEffect (() => {
    if(!cookies.get('usrNombreCompleto'))
    {
        window.location.href="./";        
    }

}, []);

return (
<div>
    <table>
        <tr>
<td>
    <img src='../img/logo.jpg' width="150px"></img>
</td>
        <td>
        <Dropdown isOpen={dropdown1} toggle={abrirCerrarDropdown1} size='sm'>
                
        <DropdownToggle caret>
            Mant. de datos          
        </DropdownToggle>

        <DropdownMenu>
            <DropdownItem onClick={()=>accionMuestras()}>Muestras biológicas</DropdownItem>   
            <DropdownItem onClick={()=>accionEspecies()}>Especies</DropdownItem>            
            <DropdownItem onClick={()=>accionTiposDeMuestra()}>Tipos de muestras biológicas</DropdownItem>            
            <DropdownItem onClick={()=>accionPaneles()}>Paneles</DropdownItem>   
            <DropdownItem onClick={()=>accionChip()}>Chips</DropdownItem>   
            <DropdownItem onClick={()=>accionArchivosDePerfiles()}>Archivos de Perfiles</DropdownItem>               
            <DropdownItem onClick={()=>accionClientes()}>Clientes</DropdownItem>
            <DropdownItem onClick={()=>accionMarcador()}>Marcadores</DropdownItem>
        </DropdownMenu>

    </Dropdown>

            </td>
            <td>
            <Dropdown isOpen={dropdown2} toggle={abrirCerrarDropdown2} size='sm'>
        <DropdownToggle caret>
            Estudios de filiación
        </DropdownToggle>

        <DropdownMenu>                     
            <DropdownItem onClick={()=>accionEstudiosFilaciones()}>Listado</DropdownItem>  
        </DropdownMenu>

    </Dropdown>


            </td>
            <td>
            <Dropdown isOpen={dropdown3} toggle={abrirCerrarDropdown3} size='sm'>
        <DropdownToggle caret>
            Seguridad
        </DropdownToggle>

        <DropdownMenu>                     
            <DropdownItem onClick={()=>accionCambioClave()}>Cambiar Mi Clave</DropdownItem>  
        </DropdownMenu>

    </Dropdown>

            </td>           
            <td>
        <Dropdown isOpen={dropdown4} toggle={abrirCerrarDropdown4} size='sm'>
                
        <DropdownToggle caret>
            Ayuda
        </DropdownToggle>

        <DropdownMenu>
            <DropdownItem onClick={()=>accionAbrirManual()}>Manual de Usuario</DropdownItem>
        </DropdownMenu>


    </Dropdown>

</td>



<td>
        <Dropdown isOpen={dropdown5} toggle={abrirCerrarDropdown5} size='sm'>
                
        <DropdownToggle caret>
            Salir
        </DropdownToggle>

        <DropdownMenu>
            <DropdownItem onClick={()=>accionCerrarSesion()}>Cerrar Sesión</DropdownItem>                        
        </DropdownMenu>

    </Dropdown>

</td>



        </tr>
    </table>
</div>
    )
}
export default Menu
