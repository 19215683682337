import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import entorno from '../config.js'
import Menu from '../components/menu.jsx';
const base_url = entorno("BASE_URL");



export const ImportarMuestrasArchivo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [listaClientes, setClientes] = useState([]);
    const [listaEspecies, setEspecies] = useState([]);
    const [listaChips, setChips] = useState([]);
    const [listaTpoMuestras, setTpoMuestras] = useState([]);
    const chips = useSelector(store => store.chips.unChip)
    const [cliNombre, setCliNombre] = useState(null);
    const [espNombre, setEspNombre] = useState(null);
    const [chipNombre, setChipNombre] = useState(null);
    const [tpoMuestraNombre, setTpoMuestraNombre] = useState(null);
    const [chipId, setChipId] = useState(null);
    const [especieId, setEspecieId] = useState(null);
    const [tpoId, setTpoId] = useState(null);
    const [error, setError] = React.useState(null);
    const [archivos, setArchivos] = useState(null);


    const[datos, setDatos]=useState(
        {
          espId:0,
          chipId: 0,
          tpoMstId: 0,
          cliId:0
        });


    useEffect(() => {


        axios.get(base_url + 'genexa/clientes')
            .then((response) => setClientes(response.data.value.data))
            .then((error) => console.log(error));

        axios.get(base_url + 'genexa/especies')
            .then((response) => setEspecies(response.data))
            .then((error) => console.log(error));

        axios.get(base_url + 'genexa/chips')
            .then((response) => setChips(response.data))
            .then((error) => console.log(error));


        axios.get(base_url + 'genexa/tipodemuestras')
            .then((res) => setTpoMuestras(res.data))
            .then((error) => console.log(error));

    }, []);



    const seleccionarEspecie = (e) => {
        console.log(e.target.value)
        var valor = Number(e.target.value);

        setDatos({
            ...datos,
            espId: valor
        })
        console.log(datos);
    };



    const subirArchivos=e=>{
        setArchivos(e);
        console.log("selecciono el archivo");
        }


    const seleccionarChip = (e) => {
        console.log(e.target.value)
        var valor = Number(e.target.value);
        setDatos({
            ...datos,
            chipId: valor
        })
        console.log(datos);
    };

    const seleccionarTpoMuestra = (e) => {
        console.log(e.target.value)
        var valor = Number(e.target.value);

        setDatos({
            ...datos,
            tpoMstId: valor
        })
        console.log(datos);
    };


    const seleccionarCliente = (e) =>{
        console.log(e.target.value)
        var valor=Number(e.target.value);
    
        setDatos({
            ...datos,
            cliId : valor                    
        })
        console.log(datos);
        
    };

    const enviarDatos= async (evento) => {
        evento.preventDefault();
        setError ('');

        var todoOK;
        todoOK=true;
        if(datos.espId===0)
        {
            setError('Campo especie es obligatorio');
            todoOK=false;            
        }

        if(datos.chipId===0)
        {
            setError('Campo chip es obligatorio');
            todoOK=false;
        }
    
        if(datos.cliId===0)
        {
            setError('Campo cliente es obligatorio');
            todoOK=false;
        }
    
        if(datos.tpoMstId===0)
        {
            setError ('Campo tipo de muestra es obligatorio');
            todoOK=false;
        }    


        if(archivos==null)
        {
            setError ('Aún no selecciona el archivo a subir');
            todoOK=false;
        }



        if(todoOK)
        {
            
            
        const f=new FormData();
        for(let index=0; index<archivos.length;index++){
            f.append("archivo",archivos[index]);
        }

        console.log(datos);
        console.log(f);

        var direc=base_url + 'genexa/muestrasbiologicas/subirarchivo' ;
        await axios.post(direc,f)
        .then(response=>{
            console.log(response.data);
            //navigate("/archivos");  
        }).catch(error=>{
            setError(error);
        })


        //voy a mandar el archivo y luego los datos
        
        var direc=base_url + 'genexa/muestrasbiologicas/datosdearchivo' ;
        await axios.post(direc,datos)
        .then(response=>{
            console.log(response.data);
            navigate("/muestras");  
        }).catch(error=>{
            setError(error);
        })
        }
    }


    return (
        <Fragment>
            <Menu></Menu>
            <hr />
            {
                error && (
                    <div className='alert alert-danger'>
                        {error}
                    </div>
                )
            }
            <h2>Importar archivo de muestras biológicas</h2>

            <form name='FrmArchivoMuestras' id='Frmcliente' className='row' onSubmit={enviarDatos} >

                <div className="col-md-5">
                    Especie:
                    <select className="form-control col-md-5" onChange={seleccionarEspecie}>
                        <option value="0" >Seleccione especie</option>
                        {
                            listaEspecies.map((especies) =>
                                <option key={especies.espId} value={especies.espId}>{especies.espNombre}</option>
                            )
                        }
                    </select>
                    <div className="col-md-5">
                        Chip:
                        <select className="form-control col-md-5" onChange={seleccionarChip}>
                        <option value="0" >Seleccione chip</option>
                            {
                                listaChips.map((chips) =>

                                    <option key={chips.chipId} value={chips.chipId}>{chips.chipNom}</option>

                                )
                            }
                        </select>
                    </div>

                    <div className="col-md-5">
                        Tipo Muestra:
                        <select className="form-control col-md-5" onChange={seleccionarTpoMuestra}>
                        <option value="0" >Seleccione tipo de muestra</option>
                            {
                                listaTpoMuestras.map((muestras) =>

                                    <option key={muestras.tpoMstId} value={muestras.tpoMstId}>{muestras.tpoMstNom}</option>

                                )
                            }
                        </select>
                    </div>

                    <div className="col-md-5">
                        Cliente:
                        <select className="form-control col-md-5" onChange={seleccionarCliente}>
                        <option value="0" >Seleccione cliente</option>
                            {
                                listaClientes.map((cliente) =>

                                    <option key={cliente.cliId} value={cliente.cliId}>{cliente.cliNom}</option>

                                )
                            }
                        </select>
                    </div>


                </div>
                <input type="file" name="archivo" onChange={(e) => subirArchivos(e.target.files)} />
                <div className="col-md-5">
                                <button 
                                className='btn btn-primary'
                                type="submit"
                                >Importar muestras</button>
                            </div>
            </form>




        </Fragment>
    )
};
export default ImportarMuestrasArchivo