import React, {Fragment, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {obtenerEstudioFilacionAccion,calcularEstudioFiliacion} from '../redux/estudioFiliacionDucks'
import Menu from '../components/menu.jsx';
import DatePicker from "react-datepicker";

const ListaEstudiosFiliaciones = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [fechaIngresoInicio, setFechaIngresoInicio] = useState(null);
    const [fechaIngresoFin, setFechaIngresoFin] = useState(null);
    const[error,setError]=React.useState(null);
    const [resultado,setResultado]= useState();


    const[filtros, setFiltros]=useState(
        {
            EstCod: '',
            Desde: '1900-01-01',
            Hasta: '1900-01-01',
            EstResCalc: '4',
            EstEstado:'P'
        });

    const estudiosFiliaciones = useSelector(store => store.estudiosFiliaciones.array)

    const handleInputChange = (evento) => {
        setFiltros({
            ...filtros,
            [evento.target.name] : evento.target.value
        })        
    }
  
    const editarElemento = (id) => {  
        navigate("/editarEstudioFiliacion/" + id);            
    }

    const handleChangeFechaIIngreso = (dateI) => {
    
        setFechaIngresoInicio(dateI);
        setFiltros({
            ...filtros,
            Desde :  dateI.toLocaleDateString("en-CA")
        })
      };


    const handleChangeFechaFIngreso = (dateI) => {
    
        setFechaIngresoFin(dateI);
        setFiltros({
            ...filtros,
            Hasta :  dateI.toLocaleDateString("en-CA")
        })
      };
    

    const handleBlur = (e) =>{
        handleInputChange(e); 
    } 


    const cambioResultado = (e) =>{
        e.preventDefault()
        setFiltros({
            ...filtros,
            EstResCalc : e.target.value                               
        })
        console.log(filtros);
    };

    const cambioEstado = (e) =>{
        e.preventDefault()
        setFiltros({
            ...filtros,
            EstEstado: e.target.value                               
        })
        console.log(filtros);
    };



    const nuevoEstudioFiliacion = () => {
        navigate("/nuevoEstudioFiliacion");    
    }


    const calcularEstudio = (id) => {
        dispatch(calcularEstudioFiliacion(id)) 

    }

    const exportarArchivo = (id) => {
        navigate("/estudiofiliacionreporte/"+id);
    }


    const obtenerEstudioFiliacion= (evento) => {
        evento.preventDefault()
        var todoOK;
        todoOK=true;
        var problemas='';
        console.log(filtros);
        if(filtros.Hasta < filtros.Desde ){
            console.log(filtros.Desde)
            console.log (filtros.Hasta)
            console.log("entro en fecha")
            setError ('Fecha fin ingreso debe ser mayor o igual a fecha inicio ingreso');
            todoOK=false;
        }
       
        if (todoOK){
            console.log(filtros);
            dispatch(obtenerEstudioFilacionAccion(filtros))
            setError('');
           
        }
        else
        {
            console.log(problemas);
          
        }

    }

    function convertDateFormat(string) {
        var info=string.substring(0,10);
        info = info.split('-');
        return info[2] + '/' + info[1] + '/' + info[0];
      }

    return (
        <div>            
            <Menu></Menu>
            <hr/>
            <h3>Lista de estudios de filiación</h3>
            <form className='row' onSubmit={obtenerEstudioFiliacion}>
            {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                     }
                <div className="col-md-4">
                    Código estudio:
                    <input 
                        name="EstCod"
                        type="text"
                        className="form-control"
                        onChange={handleInputChange}
                    /> 
                        
                </div>
                <div className="col-md-4">
                    Inicio fecha ingreso:
                    <DatePicker  className="form-control col-md-5"
                        type="text"
                        dateFormat="yyyy-MM-dd" 
                        placeholder ="Inicio fecha ingreso:"
                        defaultValue={null}
                        onChange={handleChangeFechaIIngreso}
                        selected={fechaIngresoInicio}
                         onBlur={handleBlur}
                         >
                     </DatePicker>
                     </div>

                    <div className="col-md-4">
                    Fin fecha ingreso:
                    <DatePicker  className="form-control col-md-5"
                        type="text"
                        dateFormat="yyyy-MM-dd" 
                        placeholder="Fin fecha ingreso"
                        defaultValue={null}
                        onChange={handleChangeFechaFIngreso}
                        selected={fechaIngresoFin}
                        onBlur={handleBlur}
                         >
                     </DatePicker >
                     </div>
                     <p></p>
               

                <div className="col-md-4">
                Por Resultado:
                <select className="form-control col-md-5" onChange={cambioResultado} defaultValue="3"> 
                        <option value="0" >Exclusión</option>
                        <option value="1" >Inclusión</option>
                        <option value="2" >Indeterminado</option>
                        <option value="4" >Sin cálculo aún</option>
                        <option value="3" >Todos</option>
                    </select>  
                    
                    </div>
                    <div className="col-md-4">
                    Por Estado del cálculo:
                    <select className="form-control col-md-5" onChange={cambioEstado} defaultValue="P"> 
                            <option value="P" >Pendiente de cálculo</option>
                            <option value="F" >Finalizado</option>
                            <option value="E" >Entregado al cliente</option>
                            <option value="C" >Cancelado</option>
                        </select>  
                    
                    </div>

                    <div className="col-md-5">            
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Buscar</button>
                    <button onClick={nuevoEstudioFiliacion}
                        className='btn btn-primary'
                    >Nuevo Estudio</button>
                </div>
                    <p></p>
                
            </form>                

Nota: Inclusión=menos de 2 exclusiones, Indeterminado= entre 3 y 5 exclusiones, Exclusión= mas de 5 exclusiones

<table width="100%" border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Cod. Estudio</th>
      <th scope="col">Fecha de Ingreso</th>
      <th scope="col">Fecha prometida</th>
      <th scope="col">Resultado </th>      
      <th scope="col">Acciones</th>
    </tr>
  </thead>
  <tbody>
{
    estudiosFiliaciones.map(item => (
<tr>
    <td>
        {item.estCod}
    </td>
    <td>
        {convertDateFormat(item.estFchIng)}
    </td>
    <td>
        {convertDateFormat(item.estFchProm)}
    </td>
    <td>
        {item.estResCalcNom}
    </td>
    
    <td align="left">
    <button type="button" class="btn btn-default" onClick={() => editarElemento(item.estId)}>
    <img src='./img/pencil.svg'   />
    </button>

    <button type="button" class="btn btn-default" onClick={() => calcularEstudio(item.estId)}>
    <img src='./img/calculator.svg'   />
    </button>
    <button type="button" class="btn btn-default" onClick={() => exportarArchivo(item.estId)}>
    <img src='./img/reporte.svg'   />
    </button>
    </td>
</tr>
))
    }
  </tbody>
</table>

</div>
    )
}

export default ListaEstudiosFiliaciones