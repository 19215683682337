import React from 'react'

 export default function config  (valor)  {
 var respuesta="";
    if(valor=="BASE_URL")
    {
        //respuesta="https://localhost:44395/";
        respuesta="https://api.estudiosgeneticos.net/";
    }
    return respuesta;
}

