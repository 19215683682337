import axios from 'axios'
import entorno from '../config.js'
import Cookies from 'universal-cookie';

const base_url=entorno("BASE_URL");
const cookies = new Cookies();


// constantes
const dataInicial = {
    array : [],
    offset: 0
}

// types

const OBTENER_ROLES_EXITO = 'OBTENER_ROLES_EXITO'
const ENVIAR_ROLES_EXITO = 'ENVIAR_ROLES_EXITO'


// reducer
export default function rolReducer(state = dataInicial, action){
    switch(action.type){
         case OBTENER_ROLES_EXITO:
            return {...state, array: action.payload}
        default:
            return state
    }
}

// acciones
export const obtenerRolesAccion = (nombre) => async (dispatch, getState) => {

    try {
        var filtro='';
        if(nombre.trim())
        {
            filtro='/'+nombre;
        }
        
        var direc=base_url + 'genexa/seguridad/roles' + filtro;
        console.log(direc);
        const res = await axios.get(direc);
        dispatch({
            type: OBTENER_ROLES_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}



export const enviarRolAccion = (datos) => async (dispatch, getState) => {

    try {
        const res = await axios.post(base_url + 'genexa/seguridad/roles',datos)
        dispatch({
            type: ENVIAR_ROLES_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}


export const borrarRolAccion = (id) => async (dispatch, getState) => {

    try {
        const res = await axios.delete(base_url + 'genexa/seguridad/roles/'+id)
        dispatch({
            type: ENVIAR_ROLES_EXITO,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}