import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'

import clienteReducer from './clienteDucks'
import muestrasBiologicasReducer from './muestrasBiologicasDucks'
import chipReducer from './chipDucks'
import loginReducer from './loginDucks'
import tipoMuestaReducer from './tipoMuestraDucks'
import archivosReducer from './archivosDucks'
import estudiosFiliacionesReducer from './estudioFiliacionDucks'
import panelesReducer from './panelDucks'
import especieReducer from './especieDucks'
import marcadoresReducer from './marcadoresDucks';
import usuariosReducer from './usuariosDucks';
import rolReducer from './rolesDucks';
import tareasReducer from './tareasDucks';

const rootReducer = combineReducers({
    clientes: clienteReducer,
    muestras: muestrasBiologicasReducer,    
    chips: chipReducer,
    usuarioLogin: loginReducer,
    tiposMuestas: tipoMuestaReducer,
    archivos:archivosReducer,
    estudiosFiliaciones: estudiosFiliacionesReducer,
    paneles: panelesReducer,
    especies: especieReducer,
    marcadores: marcadoresReducer,
    usuarios:usuariosReducer,
    roles:rolReducer,
    tareas:tareasReducer
})


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){
    const store = createStore( rootReducer, composeEnhancers( applyMiddleware(thunk) ))
    return store;
}

