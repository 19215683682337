import React, {Fragment, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import entorno from '../config.js'
import Menu from '../components/menu.jsx';
const base_url=entorno("BASE_URL");


export const UploadFileSNP = () => {
    const navigate = useNavigate();
    const[error,setError]=React.useState(null);
    const [archivos, setArchivos] = useState(null);

    const subirArchivos=e=>{
    setArchivos(e);
    console.log("selecciono el archivo");
    }

const insertarArchivos=async() =>{
    if(archivos)
    {
        const f=new FormData();
        for(let index=0; index<archivos.length;index++){
            f.append("archivo",archivos[index]);
        }
        
            var direc=base_url + 'genexa/archivos' ;
            await axios.post(direc, f)
            .then(response=>{
                console.log(response.data);
                navigate("/archivos");  
            }).catch(error=>{
                setError(error);
            })
        }
        else
        {
            setError('Aún no selecciona un archivo de perfil');
        }

}

 return (
    <Fragment>
                    <Menu></Menu>
            <hr/>
                            {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }
<h2>Nuevo Archivo de Perfiles</h2>
<input type="file" name="archivo" onChange={(e)=>subirArchivos(e.target.files)}/>
<button className="btn btn-primary" onClick={()=>insertarArchivos()}>Subir Archivo</button>


    </Fragment>
  )
};
export default UploadFileSNP