import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import Menu from '../components/menu.jsx';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const home = () => {

    console.log(cookies.get('usrNombreCompleto'));

    return (
<>
        <Menu></Menu>
        <hr/>
            <center>                
            sgs-filiaciones es un sistema de gestión de muestras biológicas que tiene la finalidad de centralizar, procesar y realizar cálculos sobre perfiles genéticos.
             </center>
</>

    )
}
export default home