import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import validator from 'validator'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import Cargando from './loading'
import Menu from '../components/menu.jsx';

import { modificarClienteAccion, obtenerUnClienteAccion } from '../redux/clienteDucks'

const EditarCliente = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [datos, setDatos] = useState(null);

    const { id } = useParams();

    const clientes = useSelector(store => store.clientes.uncliente)

    useEffect(() => {
        const obtenerDatos = async () => {
            try {
                setLoading(true);
                console.log("buscando un cliente")
                const res = await dispatch(obtenerUnClienteAccion(id));
                console.log(res);
                setLoading(false);
            }
            catch (error) {
                console.log(error)
            }
        }
        obtenerDatos()
    }, []);

    useEffect(() => {
        const elCliente = clientes.filter(cliente => cliente.cliId = id);
        if (elCliente.length > 0)
            return setDatos(elCliente[0]);
    }, [clientes])

    const handleInputChange = (evento) => {
        // Asigno los valores del formulario a las variables locales de la página
        // evento.target es el objeto que se esta modificando, como si fuera un objeto tipo <T
        setDatos({
            ...datos,
            [evento.target.name]: evento.target.value
        });
    };

    const enviarDatos = (evento) => {
        evento.preventDefault();
        if (!datos?.cliNom.trim())
            return setError('Nombre es obligatorio');

        if (!datos?.cliTel.trim() && !datos.cliEmail.trim())
            return setError('Teléfono o Email son obligatorios');

        if (datos?.cliEmail.trim()) {
            var email = datos?.cliEmail;
            if (!validator.isEmail(email))
                return setError('El formato del email ingresado no es correcto');
        }
        console.log(datos);
        dispatch(modificarClienteAccion(id, datos));
        //asumo que todo salió ok... me voy al listado de nuevo
        navigate("/clientes");
    }

    return (
        <Fragment>
            <Menu></Menu>
            <hr />
            <h3>Editar un cliente</h3>
            {loading
                ? (
                    <Fragment>
                        <Cargando />
                    </Fragment>
                )
                : <form name='Frmcliente' id='Frmcliente' className='row' onSubmit={enviarDatos} >
                    {
                        error && (
                            <div className='alert alert-danger'>
                                {error}
                            </div>
                        )
                    }
                    {
                        datos
                            ? <>
                                <div className="col-md-5">
                                    <label>
                                        Nombre Fantasía:<input
                                            name="cliNom"
                                            type="text"
                                            placeholder="Ingrese Nombre"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            value={datos?.cliNom || ''}
                                        />
                                    </label>
                                </div>
                                <div className="col-md-5">
                                    Dirección:<input
                                        name="cliDir"
                                        type="text"
                                        placeholder="Ingrese Dirección"
                                        className="form-control"
                                        onChange={handleInputChange}
                                        value={datos?.cliDir || ''}
                                    />
                                </div>
                                <div className="col-md-5">
                                    Teléfono:<input
                                        name="cliTel"
                                        type="text"
                                        placeholder="Ingrese Teléfono"
                                        className="form-control"
                                        onChange={handleInputChange}
                                        value={datos?.cliTel || ''}
                                    />
                                </div>
                                <div className="col-md-5">
                                    EMail:<input
                                        name="cliEmail"
                                        type="text"
                                        placeholder="Ingrese E-Mail"
                                        className="form-control"
                                        onChange={handleInputChange}
                                        value={datos?.cliEmail || ''}
                                    />
                                </div>
                                <div className="col-md-5">
                                    Nombre de Contacto:<input
                                        name="cliCntNom"
                                        type="text"
                                        placeholder="Nombre Contacto"
                                        className="form-control"
                                        onChange={handleInputChange}
                                        value={datos?.cliCntNom || ''}
                                    />
                                </div>
                                <div className="col-md-5">
                                    Teléfono de Contacto:<input
                                        name="cliCntTel"
                                        type="text"
                                        placeholder="Teléfono contacto"
                                        className="form-control"
                                        onChange={handleInputChange}
                                        value={datos?.cliCntTel || ''}
                                    />
                                </div>
                                <div className="col-md-5">
                                    Razón Social:<input
                                        name="cliRazSoc"
                                        type="text"
                                        placeholder="Razón Social"
                                        className="form-control"
                                        onChange={handleInputChange}
                                        value={datos?.cliRazSoc || ''}
                                    />
                                </div>
                                <div className="col-md-5">
                                    R.U.T.:<input
                                        name="cliRut"
                                        type="text"
                                        placeholder="R.U.T."
                                        className="form-control"
                                        onChange={handleInputChange}
                                        value={datos?.cliRut || ''}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <button
                                        className='btn btn-primary'
                                        type="submit"
                                    >Guardar</button>
                                </div>
                            </>
                            : null
                    }
                </form>
            }
        </Fragment>
    )
}

export default EditarCliente
