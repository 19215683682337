import React, {Fragment, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { obtenerTareasAccion } from '../redux/tareasDucks'
import Menu from '../components/menu.jsx';

export const Tareas = () => {
    const[filtronombre, setFiltroNombre]=useState('')

    const dispatch = useDispatch()
   
    const listaTareas = useSelector(store => store.tareas.array)
    

    const obtenerTareas= (evento) => {
        evento.preventDefault()
        dispatch(obtenerTareasAccion(filtronombre))
    }

    
    
    const handleInputChange = (evento) => {
        var todoOK;
        todoOK=true;
        var nombre = evento.target.value;
        setFiltroNombre(nombre)
    }

    return (
        <div>
            <Menu></Menu>
            <hr/>
            <h3>Lista de Tareas</h3>
            <form className='row' onSubmit={obtenerTareas}>
                <div className="col-md-4">
                    Nombre:
                    <input 
                        name="Nombre"
                        type="text"
                        placeholder="Ingrese Nombre"
                        className="form-control"
                        onChange={handleInputChange}
                        />                    
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Buscar</button>
                </div>                
            </form>      

            <table width="100%" border="1" class="table table-striped">

<thead class="thead-dark">
    <tr>
      <th scope="col">Nombre de la tarea</th>
    </tr>
  </thead>
  <tbody>
{
    listaTareas.map(item => (
<tr>
    <td>
        {item.tarNombre}
    </td>
</tr>

    ))
    }


</tbody>
</table>





        </div>
    )
}
export default Tareas