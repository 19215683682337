import React, {Fragment, useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import axios from 'axios'
import entorno from '../config.js'
import {enviarEstudioFiliacionAccion} from '../redux/estudioFiliacionDucks'
import Menu from '../components/menu.jsx';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

const base_url=entorno("BASE_URL");

const EstudioFiliacion = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [fechaIngreso, setFechaIngreso] = useState(null);
    const [fechaPrometida, setFechaPrometida] = useState(null);
    const [fechaResultado, setFechaResultado] = useState(null);
    const [listaPaneles,setPaneles]= useState([]);
    const [loading, setLoading] = useState(false);
    const[error,setError]=React.useState(null);
    

    const [madreId, setMadreId] = useState(null);
    const [padreId, setPadreId] = useState(null);
    const [hijoId, setHijoId] = useState(null);
 
    
    //const [resultadoCalculo,setResultadoCalculo]= useState();

    const[filiacion, setFiliacion]=useState(
        {
            EstId:0,
            EstCod: '',
            EstFchIng: format(new Date(), 'yyyy-MM-dd'),
            EstFchProm: format(new Date(), 'yyyy-MM-dd'),
            EstFchRes: '1900-01-01',
            EstPanId:0,
            EstMstMadre:0,
            EstMstPadre:0,
            EstMstHijo:0,
            EstResCalc:3,
            EstEstado:"P" ,
            EstMotCancelacion:'',
            EstUsrCreacion: 'vivi'
        });

        const[MstMadre, setMstMadre]=useState(
            {
              EspId:'',
              ChipId: 0,
              MstCodInt: '',
              EstPanId:0,
              TpoMstId: 0,
              MstFchIng: '1900-01-01',
              MstFchExt:'1900-01-01',
              MstFchNac:'1900-01-01',
              MstSexo:'',
              CliId:0,
              MstCodOrigen:'',
              MstTieneADN:'',
              MstEstado:'',
              MstActivo:false,
              MstUsrCreacion: 'vivi'
            });

            const[MstPadre, setMstPadre]=useState(
                {
                  EspId:'',
                  ChipId: 0,
                  MstCodInt: '',
                  TpoMstId: 0,
                  MstFchIng: '1900-01-01',
                  MstFchExt:'1900-01-01',
                  MstFchNac:'1900-01-01',
                  MstSexo:'',
                  CliId:0,
                  MstCodOrigen:'',
                  MstTieneADN:'',
                  MstEstado:'',
                  MstActivo:false,
                  MstUsrCreacion: 'vivi'
                });

                const[MstHijo, setMstHijo]=useState(
                    {
                      EspId:0,
                      ChipId: 0,
                      MstCodInt: '',
                      TpoMstId: 0,
                      MstFchIng: '1900-01-01',
                      MstFchExt:'1900-01-01',
                      MstFchNac:'1900-01-01',
                      MstSexo:'',
                      CliId:0,
                      MstCodOrigen:'',
                      MstTieneADN:'',
                      MstEstado:'',
                      MstActivo:false,
                      MstUsrCreacion: 'vivi',

                      
              
                    });


    
    const handleInputChange = (evento) => {
        
    
    var campo=evento.target.name;
    var valor;

        valor=evento.target.value;
        setFiliacion({
        ...filiacion,
        [evento.target.name] : valor
    })
        
    }


    const handleChangeFechaIngreso = (dateI) => {
        setFechaIngreso(dateI);
        setFiliacion({
            ...filiacion,
            EstFchIng : dateI.toLocaleDateString("en-CA")
            
        })
      }; 
    
      const MuestraMadre = (madre) => {
        setMstMadre({
            ...MstMadre,
            MstCodInt : madre.target.value
        })
      }; 

      const MuestraPadre = (padre) => {
        setMstPadre({
            ...MstPadre,
            MstCodInt : padre.target.value
        })
      }; 

      const MuestraHijo = (hijo) => {
             setMstHijo({
                ...MstHijo,
                 MstCodInt : hijo.target.value
             })
         };


    useEffect (function () {
        axios.get(base_url + 'genexa/paneles')
        .then((getPaneles) => setPaneles(getPaneles.data))
        .then ((error)=> console.log(error));

        axios.get(base_url + 'genexa/estudiosdepaternidad/proximonro')
        .then((res) => setFiliacion({...filiacion, EstCod : 'FIL' + res.data }))
        .then ((error)=> console.log(error));


        setFechaIngreso(new Date());

    }, []);

    const seleccionPanel = (e) =>{
        //console.log(e.target.value)
        var valor=Number(e.target.value);
    
        setFiliacion({
            ...filiacion,
            EstPanId : valor
        })
        
    };

    const mstIdHijo = async () => {

        const params = {
            EspId:0,
            ChipId: 0,
            MstCodInt:MstHijo.MstCodInt,
            TpoMstId: 0,
            MstFchIng: '1900-01-01',
            MstFchExt:'1900-01-01',
            MstFchNac:'1900-01-01',
            MstSexo:'',
            CliId: 0,
            MstCodOrigen:'',
            MstTieneADN:'',
            MstEstado:'',
            MstActivo:true,
            MstUsrCreacion: 'Admin',
        
            MstfechaIngresoInicio:'1900-01-01',
            MstfechaIngresoFin:'1900-01-01',
            MstfechaExtraccionInicio:'1900-01-01',
            MstfechaExtraccionFin:'1900-01-01'
        
        }
        try

         {
            setLoading(true);
            //console.log(params);
            //const hijo=await axios.get(base_url + 'genexa/muestrasbiologicas',{params})
            const hijo=await axios.get(base_url + 'genexa/muestrasbiologicas/filtrocodigo/' + MstHijo.MstCodInt);
                const obtenerHijo=hijo.data[0];
                setHijoId(obtenerHijo.mstId)
                setMstHijo({
                ...MstHijo,
                EspId: obtenerHijo.espId
                })
                setMstHijo({
                ...MstHijo,
                MstSexo: obtenerHijo.mstSexo
                })
            setLoading(false);

        }
        catch(error){
            console.log(error)
        }
    }
    



      const mstIdMadre = async () => {

        const params = {
            EspId:0,
            ChipId: 0,
            MstCodInt:MstMadre.MstCodInt,
            TpoMstId: 0,
            MstFchIng: '1900-01-01',
            MstFchExt:'1900-01-01',
            MstFchNac:'1900-01-01',
            MstSexo:'',
            CliId: 0,
            MstCodOrigen:'',
            MstTieneADN:'',
            MstEstado:'',
            MstActivo:false,
            MstUsrCreacion: 'Admin',
        
            MstfechaIngresoInicio:'1900-01-01',
            MstfechaIngresoFin:'1900-01-01',
            MstfechaExtraccionInicio:'1900-01-01',
            MstfechaExtraccionFin:'1900-01-01'
        
        }
        

         try
         {
             setLoading(true);
             const madre=await axios.get(base_url + 'genexa/muestrasbiologicas/filtrocodigo/' + MstMadre.MstCodInt);
             const obtenerMadre=madre.data[0];

            console.log(obtenerMadre);

            setMadreId(obtenerMadre.mstId); 
            setMstMadre({
                ...MstMadre,
                EspId: obtenerMadre.mstEspecie
            })
            setMstMadre({
            ...MstMadre,
             MstSexo: obtenerMadre.mstSexo
            })

             setLoading(false);
             }
         catch(error){
             console.log(error)
         }
     }
    


      const mstIdPadre = async () => {

        const params = {
            EspId:0,
            ChipId: 0,
            MstCodInt:MstPadre.MstCodInt,
            TpoMstId: 0,
            MstFchIng: '1900-01-01',
            MstFchExt:'1900-01-01',
            MstFchNac:'1900-01-01',
            MstSexo:'',
            CliId: 0,
            MstCodOrigen:'',
            MstTieneADN:'',
            MstEstado:'',
            MstActivo:false,
            MstUsrCreacion: 'Admin',
        
            MstfechaIngresoInicio:'1900-01-01',
            MstfechaIngresoFin:'1900-01-01',
            MstfechaExtraccionInicio:'1900-01-01',
            MstfechaExtraccionFin:'1900-01-01'
        
        }

         try
         {
             setLoading(true);
             const padre=await axios.get(base_url + 'genexa/muestrasbiologicas/filtrocodigo/' + MstPadre.MstCodInt);
             const obtenerPadre=padre.data[0];

            setPadreId(obtenerPadre.mstId);

             setMstPadre({
                 ...MstPadre,
                EspId:obtenerPadre.mstEspecie
            })
             setMstPadre({
                ...MstPadre,
                MstSexo: obtenerPadre.mstSexo
            })         
                 setLoading(false);
            }
         catch(error){
             console.log(error)
         }
     }


const mostrarObjeto= () => {
    console.log(filiacion)
    }

    const cargarDatosHijo= async () => {
        const codigo=MstHijo.MstCodInt.trim();
        console.log(codigo);
        setHijoId(0);
        setFiliacion({
            ...filiacion,
            EstMstHijo : 0
        })
        if(codigo.length>0)
        {
            const buscoHijo=await mstIdHijo()     

            if(hijoId>0)
            {
                setFiliacion({
                    ...filiacion,
                    EstMstHijo : Number(hijoId)
                })
            }
            else{
                console.log("Como no lo encontró, no le doy corte");
            }            
        }
        else
        {
            console.log("No hay codigo para buscar");            
        }
    }

    const cargarDatosMadre= async () => {
        const codigo=MstMadre.MstCodInt.trim();
        console.log(codigo);
        setMadreId(0);
        setFiliacion({
            ...filiacion,
            EstMstMadre : 0
        })
        if(codigo.length>0)
        {
            const buscoMadre=await mstIdMadre()
            if(madreId>0)
            {
                setFiliacion({
                    ...filiacion,
                    EstMstMadre : Number(madreId)
                })
            }
            else{
                console.log("Como no lo encontró, no le doy corte");
            }            
        }
        else
        {
            console.log("No hay codigo para buscar");            
        }
        console.log(filiacion);
    }


    const cargarDatosPadre= async () => {
        const codigo=MstPadre.MstCodInt.trim();
        console.log(codigo);
        setPadreId(0);
        setFiliacion({
            ...filiacion,
            EstMstPadre : 0
        })
        if(codigo.length>0)
        {
            const buscoPadre=await mstIdPadre()
            if(padreId>0)
            {
                setFiliacion({
                    ...filiacion,
                    EstMstPadre : Number(padreId)
                })
            }
            else{
                console.log("Como no lo encontró, no le doy corte");
            }            
        }
        else
        {
            console.log("No hay codigo para buscar");            
        }
    }


    const enviarDatos= (eventoEnvio) => {
        eventoEnvio.preventDefault();
        var problemas='';
        var todoOK;
        todoOK=true;

        var hijoIdActual=filiacion.EstMstHijo;
        var madreIdActual=filiacion.EstMstMadre;
        var padreIdActual=filiacion.EstMstPadre;

        if(!filiacion.EstCod.trim())
        {
        setError('Identificación del estudio es obligatorio');
        todoOK=false;
        }

        if(filiacion.EstPanId===0)
        {
        setError('Paneles por especie es obligatorio');
        todoOK=false;
        }

        if(hijoIdActual===0)
        {
            setError('Muestra del hijo es obligatoria');
            todoOK=false;
            console.log('Muestra del hijo es obligatoria');
        }
        
        if(madreIdActual===0&&padreIdActual===0)
        {
            setError('Muestra del padre o la madre es obligatoria');
            todoOK=false;
            console.log('Muestra del hijo es obligatoria');
        }
    
        if(madreIdActual>0)
        {
            if(MstMadre.MstSexo=="Macho")
            {
            setError('La muestra de la madre no es de sexo femenino');
            todoOK=false;
            }
        }

        if(padreIdActual>0)
        {
            if(MstPadre.mstSexo=="Hembra")
            {
            setError('La muestra del padre no es de sexo masculino');
            todoOK=false;
            }
        }




        if(todoOK)
        {
            console.log(filiacion);
            dispatch(enviarEstudioFiliacionAccion(filiacion));
            navigate ("/listado");  
        }
        else
        {
            console.log(problemas);
        }

    }
    return (
        <div>
            <Menu></Menu>
            <hr/>
            <Fragment>
            <h3>Nuevo estudio filiación</h3>
            <form className='row' onSubmit={enviarDatos}>
                   {
                      error && (
                          <div className='alert alert-danger'>
                              {error}
                          </div>
                      )
                   }
                <div className="col-md-5">
                    <label>
                        Identificación del estudio:<input 
                        name="EstCod"
                        value={filiacion.EstCod}
                        type="text"
                        placeholder="Identificación del estudio"
                        className="form-control"
                        onChange={handleInputChange}
                        disabled="true"
                        />
                    </label>
                </div>
                
                <div className="col-md-5">
                    <label>
                        Muestra de la madre:<input 
                        name="MstCodInt"
                        type="text"
                        placeholder="Muestra de la madre"
                        className="form-control"
                        onChange={MuestraMadre}
                        onBlur={cargarDatosMadre}
                        />
                    </label>
                    {filiacion.EstMstMadre>0 && <img src='./img/si.svg'   /> }
                    {filiacion.EstMstMadre==0 && <img src='./img/no.svg'   /> }
                </div>
                <div className="col-md-5">
                    <label>
                        Muestra del padre:<input 
                        name="MstCodInt"
                        type="text"
                        placeholder="Muestra del padre"
                        className="form-control"
                        onChange={MuestraPadre}
                        onBlur={cargarDatosPadre}
                        />
                    </label>
                    {filiacion.EstMstPadre>0 && <img src='./img/si.svg'   /> }
                    {filiacion.EstMstPadre==0 && <img src='./img/no.svg'   /> }

                </div>
                <div className="col-md-5">
                    <label>
                        Muestra del hijo:<input 
                        name="MstCodInt"
                        type="text"
                        placeholder="Muestra del hijo"
                        className="form-control"
                        onChange={MuestraHijo}
                        onBlur={cargarDatosHijo}
                        />
                    </label>
                    {filiacion.EstMstHijo>0 && <img src='./img/si.svg'   /> }
                    {filiacion.EstMstHijo==0 && <img src='./img/no.svg'   /> }
                </div>

                <div className="col-md-5">
                    Paneles por especie:
                      <select className="form-control col-md-5" onChange={seleccionPanel}>
                          <option value="0" >Seleccione Panel</option>
                              {
                                listaPaneles.map ((panel) => 
                                
                                    <option key={panel.panId} value={panel.panId}>{panel.panNombre}</option>
                                    
                                )                    
                              }
                          </select>  
                      
                </div>
            

                <div className="col-md-5">
                    Fecha de ingreso:
                  <DatePicker  className="form-control col-md-5"
                      type="text"
                      dateFormat="yyyy-MM-dd" 
                      placeholder="Fecha de Ingreso"
                      selected={fechaIngreso}
                      onChange={handleChangeFechaIngreso}
                       >
                   </DatePicker >
                </div>
                <p></p>
                <div className="col-md-5">
                    <button 
                    className='btn btn-primary'
                    type="submit"
                    >Guardar</button>
                </div>
                <p></p>
               
            </form>
            
            </Fragment>
            
        </div>
    )
}

export default EstudioFiliacion